<template>
  
  <div>
    <Preloader v-if="preloader" />
      <div class="pa-5">
        <v-row > 
    <v-col
         v-for="(image, index) in images"
      :key="index"
      class="d-flex child-flex"
      cols="6"
    >
      <v-img
        :src="image.url"
       
        aspect-ratio="1.8"
        class="grey lighten-2"
        @click="openimage(image.url)"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
   </div>           
   <v-zoomer class="zzoomerfull" v-if="dialog">      
  <img
    :src="img"
    style="object-fit: contain; width: 100%; height: 100%;"
  >
</v-zoomer>
  <v-icon  v-if="dialog" class="closebuttons" @click="closetoggle"
         >mdi-window-close</v-icon> 
   </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)
export default {
  components: {
    Preloader
  },
  name: "Splitscreen",
  data() {
    return { 
        preloader: true,
        camers : {},
        images : [],
        dialog: false,
        img : ''
    };
  },
  mounted() {
     this.initViewer();
     this.getCurrentProjectData()
  },
  watch: {
  '$store.state.currentCamera': function() {
     //this.preloader = true
  //   console.log("Current data get",this.$store.state.currentCamera)
     
      this.getCurrentProjectData();
    
  }
  }, 
  methods: {
    initViewer() {
      //console.log("init Viewer");
       this.preloader = false 
    },
    getCurrentProjectData(){   // Moduletype variable store value of marial, interior or constructuion cameras.
            this.images = [];
            let currentProject = JSON.parse(localStorage.getItem('currentProject'))
            this.currentProject = currentProject;
           // console.log(this.currentProject);
            this.drawer = false;
            this.camers = {}
          axios({ url: '/camera/'+currentProject.id, method: 'GET' })
          .then(resp => {
              this.camers = resp.data.success.filter(function(item){
                 return item.camera_active == 1;         
               }); 
                
               // console.log(this.camers);
                   var a = 1;
                   this.camers.forEach(item => {
                        this.getLattersImage(a, item)
                      a++;
                   }); 
           })
          .catch(err => {
            console.log(err);
          })
       },
       getLattersImage(a, camdata){
             //   console.log("lates images cam data",camdata);
       // axios({ url: '/camera/'+camdata.camera_id+'/latest_images', method: 'GET' })
        axios({ url: '/camera/'+camdata.camera_id+'/op_dashboard_latest_images', method: 'GET' })
          .then(resp => {
             //console.log("Lattest Image >>", resp);
             this.getImageUrl(a, resp.data.url);
           })
          .catch(err => {
            console.log(err);
          })
    },
     getImageUrl(a, url){
        
            // console.log("Lattest Image Url >>", resp.data.success.image_url);
          
         var ii = 
            {
             cam: a,
             url: url 
            } 
            ;

        this.images.push(ii);  

         //console.log(this.images);  
             
              var v = this;
              setTimeout(function () {
                  v.preloader = false
              }, 2000); 
            
          
    },
    openimage(url){
           this.dialog= true;
           this.img = url
    },
    closetoggle(){
       this.dialog= false;
    }
  }
};
</script>
<style>
.zzoomerfull {
    width: 100%;
    height: 100VH;
    border: solid 1px silver;
    position: fixed;
    top: 0;
    left: 0;
    background: #515b61b0 !IMPORTANT;;
    DISPLAY: BLOCK;
}
.closebuttons {
    position: fixed !important;
    right:20px;
    top: 60px;
    z-index: 999999;
    color: #fff !important;
}
</style>

