var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.preloader)?_c('Preloader'):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"light-green","fixed-tabs":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Recurring Timelapse ")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" One Time Timelapse ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.alert)?_c('v-alert',{attrs:{"dense":"","text":"","type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.alertmessage)+" ")]):_vm._e(),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{staticClass:"pa-5"},[_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.recurringtype),callback:function ($$v) {_vm.recurringtype=$$v},expression:"recurringtype"}},[_c('v-radio',{attrs:{"label":"Daily","color":"light-green","value":"lastoneday"}}),_c('v-radio',{attrs:{"label":"Weekly","color":"light-green","value":"last7days"}}),_c('v-radio',{attrs:{"label":"Monthly","color":"light-green","value":"lastonemonth"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time1,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","color":"light-green"},on:{"update:returnValue":function($event){_vm.time1=$event},"update:return-value":function($event){_vm.time1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start time ","prepend-icon":"mdi-clock-time-four-outline","readonly":"","format":"24hr"},model:{value:(_vm.time1),callback:function ($$v) {_vm.time1=$$v},expression:"time1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","color":"light-green"},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time1)}},model:{value:(_vm.time1),callback:function ($$v) {_vm.time1=$$v},expression:"time1"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time2,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","color":"light-green"},on:{"update:returnValue":function($event){_vm.time2=$event},"update:return-value":function($event){_vm.time2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.time2),callback:function ($$v) {_vm.time2=$$v},expression:"time2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{attrs:{"full-width":"","color":"light-green","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menu3.save(_vm.time2)}},model:{value:(_vm.time2),callback:function ($$v) {_vm.time2=$$v},expression:"time2"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.speed,"label":"Timelapse Speed","item-text":"title","item-value":"speed"},model:{value:(_vm.fps),callback:function ($$v) {_vm.fps=$$v},expression:"fps"}})],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.quality,"label":"Timelapse Quality","item-text":"title","item-value":"quality"},model:{value:(_vm.resolution),callback:function ($$v) {_vm.resolution=$$v},expression:"resolution"}})],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"mylightbutton"}},[_vm._v(" submit ")]),_c('v-btn',{attrs:{"color":"mydarkbutton"},on:{"click":_vm.clear}},[_vm._v(" Reset ")])],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.alert)?_c('v-alert',{attrs:{"dense":"","text":"","type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.alertmessage)+" ")]):_vm._e(),_c('v-form',{ref:"onetimeform",on:{"submit":function($event){$event.preventDefault();return _vm.submitontime($event)}}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDatemenu),callback:function ($$v) {_vm.startDatemenu=$$v},expression:"startDatemenu"}},[_c('v-date-picker',{attrs:{"color":"light-green"},on:{"input":function($event){_vm.startDatemenu = false}},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}})],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","color":"light-green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.enddatemenu),callback:function ($$v) {_vm.enddatemenu=$$v},expression:"enddatemenu"}},[_c('v-date-picker',{attrs:{"color":"light-green"},on:{"input":function($event){_vm.enddatemenu = false}},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}})],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"starttimemenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.starttimetime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","color":"light-green"},on:{"update:returnValue":function($event){_vm.starttimetime=$event},"update:return-value":function($event){_vm.starttimetime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","format":"24hr"},model:{value:(_vm.starttimetime),callback:function ($$v) {_vm.starttimetime=$$v},expression:"starttimetime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.starttimemenu),callback:function ($$v) {_vm.starttimemenu=$$v},expression:"starttimemenu"}},[(_vm.starttimemenu)?_c('v-time-picker',{attrs:{"color":"light-green","full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.starttimemenu.save(_vm.starttimetime)}},model:{value:(_vm.starttimetime),callback:function ($$v) {_vm.starttimetime=$$v},expression:"starttimetime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"endtimemenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.endtimetime,"transition":"scale-transition","offset-y":"","max-width":"290px","color":"light-green","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endtimetime=$event},"update:return-value":function($event){_vm.endtimetime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endtimetime),callback:function ($$v) {_vm.endtimetime=$$v},expression:"endtimetime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endtimemenu),callback:function ($$v) {_vm.endtimemenu=$$v},expression:"endtimemenu"}},[(_vm.endtimemenu)?_c('v-time-picker',{attrs:{"full-width":"","color":"light-green","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.endtimemenu.save(_vm.endtimetime)}},model:{value:(_vm.endtimetime),callback:function ($$v) {_vm.endtimetime=$$v},expression:"endtimetime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.speed,"label":"Timelapse Speed","item-text":"title","item-value":"speed"},model:{value:(_vm.fps),callback:function ($$v) {_vm.fps=$$v},expression:"fps"}})],1),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.quality,"label":"Timelapse Quality","item-text":"title","item-value":"quality"},model:{value:(_vm.resolution),callback:function ($$v) {_vm.resolution=$$v},expression:"resolution"}})],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"mylightbutton"}},[_vm._v(" submit ")]),_c('v-btn',{attrs:{"color":"mydarkbutton"},on:{"click":_vm.clear}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3","md":"3","out-line":""}},[_c('Sidebar')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }