import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    currentCamera : {},
    ArchiveImage: ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    camsetup(state, cam ){
      state.currentCamera = cam
    },
    archiveimage(state, imageid){
      state.ArchiveImage = imageid
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: 'https://api.cctvtimelapse.com/oauth/token', data : user, method: 'POST' })
          .then(resp => {

           // console.log(resp);
            const token = resp.data.access_token
            // const user = resp.data.user
            localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            commit('auth_success', token, '')
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: '/user', method: 'GET' })
          .then(resp => {

           // console.log("Get User data", resp.data.success);
            // const user = resp.data.user
           // JSON.stringify(resp.data.success);

                if(resp.data.success.cctvtimelapse_user == 1){
                  localStorage.setItem('userdata', JSON.stringify(resp.data.success));
                  resolve(resp)
                }else{
                  commit('auth_error')
                  localStorage.removeItem('token')
                  reject("You are not authorised user.")
                }

            
          })
          .catch(err => {
            console.log("tis error ", err);
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        commit('auth_error')
        localStorage.removeItem('token')
        localStorage.removeItem('userdata')
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    setCamera({ commit }, camdata){
       return new Promise((resolve) => {
        commit('camsetup', camdata)
        resolve(camdata)
      })
    },
    archiveSelectTimeSlotImage({ commit }, camdata){
      return new Promise((resolve) => {
       commit('archiveimage', camdata)
       resolve(camdata)
     })
   }
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    currentCamera: state => state.currentCamera,
    ArchiveImage: state => state.ArchiveImage
  }
});
