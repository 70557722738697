<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          class="vhfix"
          
        >
         <v-card-title>
      Manage Users 
    </v-card-title>
           <v-list 
         >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      <v-list-item  v-for="(item, i) in items"
           :key="i"
          active-class="actiaveitem" color="light-green"   @click="getUsersByProject(item)" > 
       <template >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-list-item-icon>
        </template>
      </v-list-item>  
    </v-list-item-group>
    </v-list>       
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="vhfix"
         >
            <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="firstname"
    class="elevation-1"
    :search="search"
  >
     <template v-slot:item.active="{ item }">
      <v-chip
        :color="getColor(item.active)"
        dark
      >
        {{ item.active == 1 ? 'Active' : 'Deactive' }}
      </v-chip>
    </template>
    <template v-slot:item.user_code="{ item }">
      <v-chip
        :color="getColor(item.user_code)"
        dark
      >
        {{ item.user_code == 1 ? 'User' : item.user_code == 0 ? 'Admin': ''}}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
         v-if="userdata.user_code == 2 || userdata.user_code == 0"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
         v-if="userdata.user_code == 1"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table> 
        </v-card>
      </v-col>
      
    </v-row>
      
        <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">User Profile</span>
          <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="First Name"
                  required
                  v-model="firstname"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Last Name"
                  required
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              
              <v-col cols="12"
               sm="6"
                md="6"
                >
                <v-text-field
                  label="Email"
                  required
                  readonly
                  v-model="email"
                 
                  hint="Email can't be update"
                ></v-text-field>
              </v-col>
              <v-col cols="12"
               sm="6"
                md="6" 
                >
                <v-text-field
                  label="Contact"
                
                   v-model="contactNo"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="userType"
                  :items="userTypes"
                  label="User type"
                  item-text="usertitle"
                  item-value="id"
                  required
                   :readonly="userdata.user_code == 2 ? false : true"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
          v-model="e7"
          :items="items"
          label="Projects"
          item-text="project_name"
          item-value="id"
          multiple
          chips
          hint="Select Projects for user"
          persistent-hint
          :readonly="userdata.user_code == 2 ? false : true"
        >
        </v-select>
              </v-col>
               <!-- <v-col
                cols="12"
                sm="4"
              >
          <v-checkbox
          v-model="constcameracheck"
          label="Const Camera"
          ></v-checkbox>
                </v-col>
                 <v-col
                cols="12"
                sm="4"
              >
          <v-checkbox
          v-model="billingcheck"
          label="Billing"
          ></v-checkbox>
                </v-col> -->
                 <v-col
                cols="12"
                sm="4"
              >
          <v-checkbox
          v-model="activecheck"
          label="Active"
          ></v-checkbox>
                </v-col>
            </v-row>
          </v-container>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="UpdateUserInfo"
            v-if="userdata.user_code == 2 || userdata.user_code == 0"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
//import Sidebar from '../../../components/SettingSidebar'

 
export default {
  components: {
    Preloader,

  },
  name: "ChangePassword",
  data() {
    return { 
      preloader : false,
       items: {} ,
       selectedItem: 0, 
       search: '',
        dialog: false,
      dialogDelete: false,
       e7 :[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstname',
          },
          { text: 'Last Name ', value: 'lastname' },
          { text: 'Email', value: 'email' },
          { text: 'Active', value: 'active' },
          { text: 'User type', value: 'user_code' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
    
     desserts: [],
     editedIndex: -1,
    
      editDate : {},
      userType : 1,
      userTypes : [
         {
           id : 1,
           usertitle : 'User'
         },
         {
           id : 0,
           usertitle : 'Admin'
         }
      ],
      constcameracheck : false,
      billingcheck : false,
      activecheck : false,
      firstname : '',
      lastname : '',
      contactNo : '',
       alert: false,
          alertmessage: '',
          alertType: '', 
          userdata :'',
    }
  },
  mounted() {
     this.getPorjects();
       this.userdata = JSON.parse(localStorage.getItem('userdata')); 
  },
  watch: {
  '$store.state.currentCamera': function() {
    
     console.log("Current data get")
    
  },
   dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

  },
   computed: {
      params() {
      return {
        firstname : this.firstname, 
        lastname : this.lastname,
        contact : this.contactNo,
        active :  this.activecheck ? 1:0,
        project_ids : this.e7.toString(),
        user_code : this.userType
      }
    }
    },
  methods: {
     getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

              this.getUsersByProject(this.items[0]);
          })
          .catch(err => {
            console.log(err);
          })
      },
      getUsersByProject: function(pdata){
        this.preloader = true;
        axios({ url: '/project/'+pdata.id+'/users', method: 'GET' })
          .then(resp => {
            //console.log("User", resp.data.success);
            this.desserts = resp.data.success;
               this.preloader = false;
          })
          .catch(err => {
              this.preloader = false;
            console.log(err);
          })
      },
      editItem (item) {
         // console.log("item", item);
          this.editDate = item 
           this.e7 = item.project_ids.split(',')
            this.dialog = true
            this.userType =  item.user_code
            this.firstname = item.firstname
            this.lastname = item.lastname
            this.email = item.email
            this.contactNo = item.contactNo
            if(item.billing_access == 1){
            this.billingcheck = true
            }
             if(item.cont_camera_aceess == 1){
            this.constcameracheck = true
            }

             if(item.active == 1){
             this.activecheck = true
            }else{
             this.activecheck = false
            }
      
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
      sidebartoggle(){
        console.log("Toggle working");
      },
      UpdateUserInfo(){
              
              // console.log("Form Date ",this.params);

               axios({ url: '/users/'+this.editDate.id, data: this.params, method: 'PUT' })
          .then(resp => {
            
             if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
                     var self = this;
                      setTimeout(function(){ 
                         self.dialog = false;
                          self.alert = false;
                      }, 1000);
                 
            
          })
          .catch(err => {
            console.log(err);
          })
      },
      getColor (calories) {
        if (calories == 0) return '#ec665cfc'
        else if (calories == 1 ) return '#8bc34ac2'
        else return '#ff9800'
      },
  }
};
</script>
<style>
.vhfix{
  max-height:91vh;
  overflow:auto;

} 
</style>

