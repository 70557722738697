<template>
  <nav>
    <v-app-bar app dense class="headercolor">
      <v-app-bar-nav-icon class="whitercolor" @click="drawer = !drawer" title="Projects"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text logolink">
        <span class="font-weight-bold shado"><img class="logo" @click="setrouter('/portal')"  :src="logo" /></span>
      </v-toolbar-title>
          <v-tooltip bottom >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          class="projectname white--text"
        >{{ currentProject.project_name }} </span>
      </template>
      <span>{{ currentProject.company }}</span>
    </v-tooltip>
          
           <v-select
           v-if="camers.length > 1"
          :items="camers"
          item-text="camName"
          item-value="iindex"
          label="Select Camera"
          class="light small camdropdown"
          v-model="camid"
          x-small
          @change="selectCamera(camid)"
          solo
          outlined
          color="white"
        ></v-select>
        <!-- <select class="cameraselect" @change="selectCamera($event)" v-if="camers.length > 1 ">
          <option v-for="(camera, index ) in camers" :key="camera.camera_id" :value="index"> CAM {{ index + 1 }} </option>
        </select> -->
        <v-spacer></v-spacer>
      <!-- dropdown menu -->
     
       <!-- <v-btn text :color="'/portal/archive' == activelink ? activecolor: 'white'" small  @click="setrouter('/dashboard/archive')">
            <v-icon left>mdi-calendar-range</v-icon> 
            <span>Archive </span>
       </v-btn> -->
     
       <v-btn text :color="'/portal/timelapse' == activelink ? activecolor: 'white'" small @click="setrouter('/portal/timelapse')">
            <v-icon left>mdi-clock-time-four-outline</v-icon> 
            <span>Timelapse</span>
       </v-btn>
    
       <v-btn text  :color="'/portal/compare' == activelink ? activecolor: 'white'" small @click="setrouter('/portal/compare')">
            <v-icon left>mdi-compare</v-icon> 
            <span> Compare </span>
       </v-btn>
    
       <v-btn text :color="'/portal/splitscreen' == activelink ? activecolor: 'white'" small @click="setrouter('/portal/splitscreen')">
            <v-icon left>mdi-rhombus-split-outline</v-icon> 
            <span> Split Screen </span>
       </v-btn>
     
       <v-btn text :color="'/portal/live' == activelink ? activecolor: 'white'" small @click="setrouter('/portal/live')" v-if="current_cam.cctv == 1">
            <v-icon left>mdi-monitor</v-icon> 
            <span> Live  </span>
       </v-btn>

       <v-btn text  title="To Home"  :color="'/portal' == activelink ? activecolor: 'white'" small @click="setrouter('/portal')" v-if="'/portal' !== activelink">
            <v-icon left>mdi-undo-variant</v-icon> 
            <span> Back </span>
       </v-btn>
     
      
      <v-menu
      :disabled="manutoggle" 
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
     
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="secondcolor"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ userdata.firstname }} {{ userdata.lastname }}   <v-icon
          dark
          right
        >
          mdi-cog-outline
        </v-icon> 
        </v-btn>
      </template>

      <v-card  class="mysettings">
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="light-green">
              <span class="white--text headline">{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ userdata.firstname }} {{ userdata.lastname }}</v-list-item-title>
              <v-list-item-subtitle>{{ userdata.user_code == 2 ?  'Super Admin': userdata.user_code == 1 ? 'User': 'Admin'  }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="red lighten-1"
          dark
          v-bind="attrs"
          v-on="on"
          @click="logout()"
        >
          mdi-logout
        </v-icon>
      </template>
      <span>Logout </span>
    </v-tooltip>
            </v-list-item-action>
          </v-list-item>
         

        </v-list>

        <v-divider></v-divider>

       <v-list dense>
      <v-subheader>Settings</v-subheader>
      <v-list-item-group
        color="light-green"
      >
        <v-list-item :color="'/portal/settings/change-password' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/change-password')">
          <v-list-item-icon>
            <v-icon>mdi-lock-reset</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Update Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="useraccesssuperadmin" :color="'/portal/settings/adduser' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/adduser')">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Add User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  :color="'/portal/settings/managa-user' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/managa-user')">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
   
        <v-list-item v-if="useraccess" :color="'/portal/settings/schedule-email' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/schedule-email')">
          <v-list-item-icon>
            <v-icon>mdi-email-sync</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Email Trigger </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="useraccess" :color="'/portal/settings/camera-notifications' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/camera-notifications')">
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Camera Off alert </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="useraccess" :color="'/portal/settings/camerainfo' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/camerainfo')">
          <v-list-item-icon>
            <v-icon>mdi-cctv</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Camera Status </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  v-if="useraccess" :color="'/portal/settings/customize-timelapse' == activelink ? activecolor: 'grey darken-3'" @click="setrouter('/portal/settings/customize-timelapse')">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Create Custom Timelapse </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <v-list-item  @click="support">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Support </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    
      </v-card>
    </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary  >
       <v-list >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      <v-list-item 
        v-for="(item, i) in items"
        :key="i"
       active-class="actiaveitem" color="light-green"  @change="selectConstruction(item)"> 
       <template >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-list-item-icon>
        </template>
      </v-list-item>  
       </v-list-item-group>
    </v-list>
    </v-navigation-drawer>
  </nav>
</template>


<script>
import axios from 'axios'
import logo from '../assets/cctv-timelapse-icon.png';
export default {
  data: () => ({
    drawer: null,
    links: [
      { icon: 'home', text: 'Homes', route: '/'},
      { icon: 'contacts', text: 'About', route: '/portal/about'},
    ],
    userdata : {},
    items: {},
    camers: {},
    currentProject: '',
    currentCamere : '',
    fav: true,
      menu: true,
      message: false,
      hints: true,
      activelink : '/portal',
      activecolor :  'light-green',
      dialogm1: '',
      current_cam : '',
      manutoggle : false,
        useraccess : false,
        useraccesssuperadmin : false,
        camid : 0,
         selectedItem: 0, 
         logo : logo,
  }),
    watch: {
  '$store.state.currentCamera': function() {
    
      this.current_cam = this.$store.state.currentCamera
  },
   '$route':  function (to){
        
       // console.log("Router change", to)
               if(to.name == 'changepassword' || to.name == 'adduser'  || to.name == 'schedulemail' || to.name == 'cameranotification' || to.name == 'camerainfo' || to.name == 'customizetimelapse' ){
                 this.manutoggle = true;
                 this.menu = false
               }else{
                   this.manutoggle = false;
               }
    }
  }, 
   computed : {
      //isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    created(){
        this.userdata = JSON.parse(localStorage.getItem('userdata'))
         if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
              this.useraccess = true
        }
         if(this.userdata.user_code == 2 ){
           this.useraccesssuperadmin = true
         }
        this.getPorjects();
        this.currentRouteName();
      },
    methods: {

      setrouter: function (url) {
        if(this.$route.path !== url){
             this.$router.push(url)
          //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
        }
         
          //console.log(url);
      },
      logout: function () {
         //console.log("logout butten pressed");
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

              if(this.items.length > 0){
                let currentProject = JSON.parse(localStorage.getItem('currentProject'));
                 //console.log("currentProject",currentProject);
                  if(currentProject == null){
                     this.initialSetup(this.items[0]);
                   
                  }else{
                     // console.log("Project Already Set")
                     this.initialSetup(currentProject);
                   
                  }
              }
          })
          .catch(err => {
            console.log(err);
          })
      },
      selectConstruction: function (projectdata) {
               localStorage.setItem('currentCamIndex', 0)
                  this.camid = 0;
          localStorage.setItem('currentProject', JSON.stringify(projectdata))

           this.getCurrentProjectData('construction_camera')
      },
      selectMaterial :function (projectdata) {
        
          localStorage.setItem('currentProject', JSON.stringify(projectdata))
          this.getCurrentProjectData('material')
      },
       selectInterior : function (projectdata) {
          
           localStorage.setItem('currentProject', JSON.stringify(projectdata))
          this.getCurrentProjectData('interior')
       },
       getCurrentProjectData(){   // Moduletype variable store value of marial, interior or constructuion cameras.
            let currentProject = JSON.parse(localStorage.getItem('currentProject'))
            this.currentProject = currentProject;
           // console.log(this.currentProject);
            this.drawer = false;
            this.camers = {}
          axios({ url: '/camera/'+currentProject.id, method: 'GET' })
          .then(resp => {

               this.preloader =  false;
                var a = 0;
                var b = 1;
              this.camers = resp.data.success.filter(function(item){
                        item.camName = "CAM "+ b;
                        item.iindex = a;
                        a++;
                       b++; 
                 return item.camera_active == 1;          
               });
                  
               //console.log("Cameras", this.camers);
            
                var currentcam = localStorage.getItem('currentCamIndex');
               
               
                if(currentcam === null){
                    console.log("in if");
                  this.setCamera(this.camers[0]);
                  localStorage.setItem('currentCamIndex', 0);
                  this.camid = 0;
                } else {
                 
                  localStorage.setItem('currentCamIndex', currentcam)
                  this.setCamera(this.camers[currentcam]);
                  this.camid = parseInt(currentcam);
                  
                    console.log("in else ", this.camers[currentcam]); 

                    if(currentcam === undefined ){
                    this.setCamera(this.camers[0]);
                    localStorage.setItem('currentCamIndex', 0);
                     this.camid = 0;
                    }
                 
                }
                    
                
                 //console.log("This is current camera", this.camers[0]);
               
           })
          .catch(err => {
            console.log(err);
             this.preloader =  false;
          })
       },
        selectCamera(event){
//console.log(event);

            localStorage.setItem('currentCamIndex', event)
           // let c =  this.camers[event.target.value]
            // console.log("select camera data ", this.camersevent);
             this.setCamera(this.camers[event])
           //  this.camid(event)
        },
        initialSetup(initialidata){
              //  console.log("initialidata", initialidata);
                  localStorage.setItem('currentProject', JSON.stringify(initialidata))
                  this.getCurrentProjectData()
        },
        setCamera(data) {
          this.$store.dispatch('setCamera', data)
       .then(() => 
               console.log("camera set data") 
       )
       .catch(err => console.log(err))
      },
      currentRouteName() {
       // console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;

     },
      support: function () {
                  window.open(
            'https://cctvtimelapse.com/contact/',
            '_blank'
          );
      
      }
    
    }
}
</script>
<style>
 .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
    padding-left: 42px !important;
}
.v-list.v-sheet.theme--light {
    padding: 0;
}
.projectname {
    font-size: 18px !important;
    padding-left: 16px;
    top: 2px;
    position: relative;
}
.cameraselect {
    max-width: 150px;
    top: 1px;
    left: 21px;
    display: inline-block;
    padding: 0px 8px;
    position: relative;
    color: #929292;
    font-weight: 300;
}
.theme--light.v-sheet{
 background:#fff;
}
.camdropdown {
    top: 15px;
    left: 15px;
    max-width: 113px;
}
.camdropdown .v-input__slot{
  min-height: 30px !important;
}
span.font-weight-bold.shado {
    text-shadow: -2px 4px 4px black;
}

.logo {
    width: 35px;
    position: relative;
    top: 3px;
}
.logolink{
  cursor:pointer;
}
</style>