<template>
  
  <div>
    <Preloader v-if="preloader" />
      <v-card>
    <v-tabs
      v-model="tab"
      background-color="light-green"
      fixed-tabs
      dark
      
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Timelapse
        
      </v-tab>

      <v-tab href="#tab-2" v-if="custometab">
        Custom Timelapse
       
      </v-tab>

      <v-tab @click="setrouter" v-if="useraccess">
        Create Timeplpse
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-1"
      >
        <v-card flat>
          <div  class="videostyle">
           <video  class="videostyle" controls="true" ref="timelapsvideo" >
         </video>
         <!-- <v-btn
      class="mx-2 mixoperation download"
      fab
      dark
      small
      color="#ff5566"
      @click ="downloadTimelaps"
    >
      <v-icon dark>
        mdi-download-circle
      </v-icon>
    </v-btn> -->

 <v-btn
      class="mx-2 mixoperation share"
      fab
      dark
      small
      color="#ff5566"
      @click="openbox"
    >
      <v-icon dark>
        mdi-share-variant
      </v-icon>
    </v-btn>

          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="tab-2"
         v-if="custometab"
      >
        <v-card flat>
          <div  class="videostyle">

           <video class="videostyle"  controls="true" ref="custome_timelapsvideo" >
         </video>
         <!-- <v-btn
      class="mx-2 mixoperation download"
      fab
      dark
      small
      color="#ff5566"
       @click ="downloadCustomeTimelaps"
    >
      <v-icon dark>
        mdi-download-circle
      </v-icon>
    </v-btn> -->

 <v-btn
      class="mx-2 mixoperation share"
      fab
      dark
      small
      color="#ff5566"
      @click="openboxSecond"
    >
      <v-icon dark>
        mdi-share-variant
      </v-icon>
    </v-btn>
            <div class="detailsBox">
                 <table> 
                    <tr>
                       <td>Type</td>
                       <td> &nbsp; : {{ this.details.type }}</td>
                    </tr>
                    <tr>
                       <td>Start Date </td>
                       <td> &nbsp; : {{ this.details.start_date }}</td>
                    </tr>
                    <tr>
                       <td>End Date </td>
                       <td> &nbsp; : {{ this.details.end_date }}</td>
                    </tr>
                    <tr>
                       <td>Start Time </td>
                       <td> &nbsp; : {{ this.details.start_time }}</td>
                    </tr>
                    <tr>
                       <td>End Time</td>
                       <td> &nbsp;: {{ this.details.end_time }}</td>
                    </tr>
                    <tr>
                       <td>Timelapse Speed</td>
                       <td> &nbsp; : {{ this.details.timelapse_fps }}</td>
                    </tr>
                     <tr>
                       <td>Timelapse Quality</td>
                       <td> &nbsp; : {{ this.details.quality }}</td>
                    </tr>
                 </table>
            </div>
         </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
           <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        color="light-green"
         v-model="sharebox"
      >
        <template >
          <v-card>
            <v-toolbar
               color="light-green"
              dark
            > Share By Email</v-toolbar>
            <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
          <v-col
          cols="12"
         sm="12"
        >
        <v-text-field
          v-model="email"
          label="Email"
          required
          :rules="emailrules"
        ></v-text-field>
       </v-col>
        <v-col
          cols="12"
          sm="12"
        >
        <v-text-field
          v-model="subject"
          label="Subject"
          required
        ></v-text-field>
         </v-col>
      
        
      <v-col
          cols="12"
          
        >
        <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        Send  <v-progress-circular
        v-if="send"
      indeterminate
      color="green"
    ></v-progress-circular>
      </v-btn>
      <v-btn @click="sharebox = false"
       color="mydarkbutton"
      >
      Cancel
      </v-btn>
       </v-col>
      </v-row>
    </v-container>
    </v-form> 
          </v-card>
        </template>
      </v-dialog> 

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        color="light-green"
         v-model="custometimelapsbox"
      >
        <template >
          <v-card>
            <v-toolbar
               color="light-green"
              dark
            > Share By Email</v-toolbar>
            <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitcustometimelaps"
    >

         <v-container>
          <v-row> 
          <v-col
          cols="12"
         sm="12"
        >
        <v-text-field
          v-model="email"
          label="Email"
          required
          :rules="emailrules"
        ></v-text-field>
       </v-col>
        <v-col
          cols="12"
          sm="12"
        >
        <v-text-field
          v-model="subject"
          label="Subject"
          required
        ></v-text-field>
         </v-col>
        
      <v-col
          cols="12"
          
        >
        <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        Send  <v-progress-circular
        v-if="send"
      indeterminate
      color="green"
    ></v-progress-circular>
      </v-btn>
      <v-btn @click="custometimelapsbox = false"
       color="mydarkbutton"
      >
      Cancel
      </v-btn>
       </v-col>
      </v-row>
    </v-container>
    </v-form> 
          </v-card>
        </template>
      </v-dialog> 
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
export default {
  components: {
    Preloader
  },
  name: "Timelaps",
  data() {
    return { 
        preloader: true,
        regulertimelaps : '',
        currentcam : {},
         tab: null,
         details : {}, 
         response :{}, 
         custometab : true,
         sharebox : false,
         custometimelapsbox : false,
             email : '',
             message :'',
             subject : '',
              emailrules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
             
            ],
            valid : true,
            timelapsurl:'',
            custometimelapsurl: '',
            send : false,
            useraccess : false,
            project : '',
            org : '',
            city : '',
    }
  },

  mounted() {
       this.userdata = JSON.parse(localStorage.getItem('userdata'))
         if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
              this.useraccess = true
        }
     this.initViewer();
     this.getTimelapsVideos();
    //  const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
    // this.playVideo(src);
   
  },
  watch: {
  '$store.state.currentCamera': function() {
     //this.preloader = true
     //console.log("Current data get",this.$store.state.currentCamera);
      this.currentcam = this.$store.state.currentCamera;
       this.getTimelapsVideos();

        let currentProject = JSON.parse(localStorage.getItem('currentProject'));
 
         console.log('currentProject', currentProject);

         this.project = currentProject.project_name;
         this.org = currentProject.company;
         this.city = currentProject.city;
      

  }
  },
    computed: {
      params() {
      return {
        to : this.email, 
        message : '',
        subject : this.subject,
        attachment : this.timelapsurl,
         project : this.project,
        org : this.org,
        city : this.city,

      }
    },
        paramscustome() {
      return {
        to : this.email, 
        message : '',
        subject : this.subject,
        attachment : this.custometimelapsurl,
        project : this.project,
        org : this.org,
        city : this.city,
      }
    }  
  },
  methods: {
    initViewer() {
      //console.log("init Viewer");
       this.preloader = false 
          
            let currentProject = JSON.parse(localStorage.getItem('currentProject'));

          this.project = currentProject.project_name;
         this.org = currentProject.company;
         this.city = currentProject.city;
    
    },
    getTimelapsVideos(){
        this.currentcam = this.$store.state.currentCamera;
        this.preloader = false;
        //var camid = this.currentcam.camera_id;
       // console.log("current Camera", this.currentcam);
           axios({ url: '/camera/'+this.currentcam.camera_id+ '/list/timelapse', method: 'GET' })
          .then(resp => {
                //console.log(resp.data.success);
                  this.response = resp.data.success;
                  this.$refs.timelapsvideo.src = resp.data.success.timelapse.url;
                  this.timelapsurl = resp.data.success.timelapse.url;
                  if(resp.data.success.custom_timelapse == 'Not enabled.'){
                                
                                this.custometab = false;
                  }else{
                     this.custometab = true;
                       this.$refs.custome_timelapsvideo.src = resp.data.success.custom_timelapse.url;
                       this.custometimelapsurl = resp.data.success.custom_timelapse.url;
                      this.details = resp.data.success.custom_timelapse.details
                  }

                 
                  

                 
                // this.$refs.timelapsvideo.play()
                 this.preloader= false
           })
          .catch(err => {
            console.log(err);
          })
    },
    downloadTimelaps(){
        const myImage = this.response.timelapse.url;

       var res = myImage.split("?");

       var filenames = res[0].split('/');

         var filename  = filenames[filenames.length - 1 ];

        // console.log(filename);

      //  console.log("Edit data", myImage);
        var a = document.createElement("a"); //Create <a>
        a.href =  'data:video/mp4,'+myImage; //Image Base64 Goes here
        a.download = filename; //File name Here
        a.target = '_blank';
        a.download;
        a.click(); //Downloaded file
    },
    downloadCustomeTimelaps(){
       const myImage = this.response.custom_timelapse.url;

       var res = myImage.split("?");

       var filenames = res[0].split('/');

         var filename  = filenames[filenames.length - 1 ];

         //console.log(filename);

      //  console.log("Edit data", myImage);
        var a = document.createElement("a"); //Create <a>
        a.href =  'data:video/mp4,'+myImage; //Image Base64 Goes here
        a.download = filename; //File name Here
        a.target = '_blank';
        a.download;
        a.click(); //Downloaded file
    },
    setrouter: function () {
          this.$router.push('/portal/settings/customize-timelapse');
          //console.log(url);
      },
       async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.sharetimelaps(this.params) // action to login
      }
    },
      sharetimelaps(sharedata){

         console.log("data", sharedata);
           this.send = true; 

          axios({ url: 'https://secure.cctvtimelapse.com/api/send-with-link', data : sharedata, method: 'post' })
           .then(resp => {
                  console.log(resp);
                   this.sharebox = false;
                   this.send = false;
         })
          .catch(err => {
            console.log(err)
             this.send = false;
               this.snackbar = true;
            this.text = `Email delivery faild.`;
           }) 
      },
      openbox(){
         this.sharebox = true;
      },
      openboxSecond(){
           this.custometimelapsbox = true;
      },
      async submitcustometimelaps() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.sharecustometimelaps(this.paramscustome) // action to login
      }
    },
       sharecustometimelaps(sharedata){
        
         console.log("data", sharedata);
         this.send = true;

          axios({ url: 'https://secure.cctvtimelapse.com/api/send-with-link', data : sharedata, method: 'post' })
           .then(resp => {
                  console.log(resp);
                   this.custometimelapsbox = false;
                   this.send = false;
         })
          .catch(err => {
            console.log(err)
             this.send = false;
               this.snackbar = true;
            this.text = `Email delivery faild.`;
           })
      }
  }
}
</script>
<style>
 .videostyle {
    margin: auto;
    display: block;
    width: 100%;
}
video {
    max-width: 100%;
    height: 85.6vh;
}
.detailsBox {
    position: absolute;
    right: 0;
    top: 0;
    background: #ebe9e9;
    box-shadow: 0 0 5px 3px #ccc;
    border-radius: 5px;
    padding: 6px 20px;
    opacity: 0.5;
}

.detailsBox:hover {
    opacity: 1;
}
.mixoperation {
    position: absolute;
}
.download{
   top : 36%
}
.share{
  top : 45%

}
</style>

