<template>
  <v-card height="91.5vh">
    <v-navigation-drawer
      absolute
      permanent
      right
      width="100%"
    >
      <template v-slot:prepend>
          <v-list>
          <v-list-item>
            <v-list-item-avatar color="light-green">
              <span class="white--text headline">{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ userdata.firstname }} {{ userdata.lastname }}</v-list-item-title>
              <v-list-item-subtitle>{{ userdata.user_code == 2 ?  'Super Admin': userdata.user_code == 1 ? 'Admin': 'User'  }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="red lighten-1"
          dark
          v-bind="attrs"
          v-on="on"
          @click="logout()"
        >
          mdi-logout
        </v-icon>
      </template>
      <span>Logout </span>
    </v-tooltip>
            </v-list-item-action>
          </v-list-item>
         

        </v-list>
      </template>

      <v-divider></v-divider>

      <v-list dense>
      <v-subheader>Settings</v-subheader>
      <v-list-item-group
        color="light-green" 
      >
        <v-list-item  @click="setrouter('/portal/settings/change-password')">
          <v-list-item-icon>
            <v-icon>mdi-lock-reset</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Update Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="setrouter('/portal/settings/adduser')" v-if="useraccesssuperadmin">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content >
            <v-list-item-title >Add User</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="setrouter('/portal/settings/managa-user')">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
   
        <v-list-item @click="setrouter('/portal/settings/schedule-email')" v-if="useraccess">
          <v-list-item-icon>
            <v-icon>mdi-email-sync</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Email Trigger </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  @click="setrouter('/portal/settings/camera-notifications')" v-if="useraccess">
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Camera Off alert</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="setrouter('/portal/settings/camerainfo')" v-if="useraccess">
          <v-list-item-icon>
            <v-icon>mdi-cctv</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Camera Status </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="setrouter('/portal/settings/customize-timelapse')" v-if="useraccess">
          <v-list-item-icon>
            <v-icon>mdi-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Create Custom Timelapse  </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="support">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Support </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list-item-group>
    </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
//import axios from 'axios'

export default {
  data: () => ({
      userdata: {},
     useraccess : false,
      activecolor :  'light-green',
  }),
     created(){
        this.userdata = JSON.parse(localStorage.getItem('userdata')); 
        
        if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
              this.useraccess = true
        }
         if(this.userdata.user_code == 2 ){
           this.useraccesssuperadmin = true
         }
        console.log("Setting Sidebar", this.$route.path);

      },
    watch: {
  '$store.state.currentCamera': function() {
    
      this.current_cam = this.$store.state.currentCamera
  },
  '$route':  function (){
       //this.activelink = this.$route.path;
  }
},
methods: {
      setrouter: function (url) {
        if(this.$route.path !== url){
             this.$router.push(url)
       //   console.log("current router ", this.$route.path);
          //this.activelink = this.$route.path;
        }
         
          //console.log(url);
      },
      logout: function () {
         //console.log("logout butten pressed");
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      support: function () {
                  window.open(
            'https://cctvtimelapse.com/contact/',
            '_blank'
          );
      
      }
  }
}
</script>
<style>

</style>