<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="9" class="left backgroundimage">
       
      </v-col>
      <v-col cols="3" class="right">
        <div v-if="loginerror">
        <h2>LOGIN</h2>
        <validation-observer ref="observer">
          <v-form @submit.prevent="submit">
            <validation-provider v-slot="{ errors }" name="Name" rules="required|email">
              <v-text-field
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="email" rules="required">
              <v-text-field
                v-model="password"
                :error-messages="errors"
                label="Password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
            </validation-provider>
            <div class="text-center">
              <v-btn class="signin-btn singbuttons" type="submit" rounded>
                Sign In
              </v-btn>
              
               <div class="mt-5">
                 <p  @click="checkgorgorpassword"
                  >Forgot password ?</p>
                 </div>
            </div>
             
          </v-form>
          
                 
            
        </validation-observer>
        <div class="text-center">
               <v-alert
      dense
      outlined
      :type="type"
      class="mt-5"
      v-if="error"
    >
     {{errorMessage}}
    </v-alert>

     </div>
     </div>
     <div v-if="!loginerror">
        <h2>Forgot Password </h2>
        <p class=""> Enter your register email address for reset password.</p>
        <validation-observer ref="observer">
          <v-form @submit.prevent="forgotPassword">
            <validation-provider v-slot="{ errors }" name="Name" rules="required|email">
              <v-text-field
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
            </validation-provider>
          
            <div class="text-center">
              <v-btn class="signin-btn singbuttons" type="submit" rounded>
                Send
              </v-btn>
            </div>
          </v-form>
           
        </validation-observer>
        <div class="text-center">
          
            <div class="mt-5">
               <p @click="checkgorgorpassword"> 
                 I have password Login Now
                 </p>
                  

                 </div>

               <v-alert
      dense
      outlined
      :type="type"
      class="mt-5"
      v-if="error"
    >
     {{errorMessage}}
    </v-alert>

    
     </div>
     </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
import axios from 'axios'
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    email: '',
    password: null,
    showPass: false,
    error : false,
    errorMessage : '',
    type : '',
    loginerror: true
  }),
  computed: {
    params() {
      return {
        username: this.email,
        password: this.password,
        grant_type : 'password',
        client_id : 2,
        client_secret : 'adfCEgbQA2ybpuqgLtoJhAuRedAJBQlp651WDElI',
        scope : '',
        
      }
    }
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
         //console.log("Hello i am here.");
        this.login(this.params) // action to login
      }
    },
    checkgorgorpassword(){
       this.loginerror = !this.loginerror ;

    },
    clear() {
      // you can use this method to clear login form
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    },
    login(data) {
        this.$store.dispatch('login', data)
       .then(() => 
        this.getUser()
       )
       .catch(err => {
         console.log(err)
         this.error = true;
         this.errorMessage = 'Something went wrong please check your Email or password.';
         this.type = 'error';
         })
      },
     getUser(){ 
     this.$store.dispatch('getUser')
       .then(() => 
          this.$router.push('/')
       )
        .catch(err => {
         console.log(err)
         this.error = true;
        this.errorMessage = 'You are not authorized to access account. Please contact to admin';
        this.type = "error";
         })
     },
     async forgotPassword() {
           console.log(this.email);

           var email = {
               email : this.email
           };
        axios({ url: '/password/email', data : email, method: 'POST' })
.then(response => {
    console.log(response, "response Link send Sccessful");
      if(response.data.success){
         this.error = true;
         this.type = 'success';
           this.errorMessage = 'Reset password link send successfull.';
            this.loginerror = !this.loginerror ;
      }else{
        this.error = true;
         this.type = 'error';
         this.errorMessage = 'Something went wrong please check your email.';
      }
       
      }) 
      .catch(err => {
         console.log(err)
         this.error = true;
        this.errorMessage = 'Something went please check your email.';
        this.type = "error";
         })  
      
    },
  } 
}
</script>
<style>
  .loginerror{
     
  }
</style>