<template>
  <div>
    <Preloader v-if="preloader" />
      <video  class="videostyle" controls="true" src="" ref="vodvideos" v-if="vod">
         </video>
     <iframe id="ptzframe" :src="strimurl" frameborder="0" allowfullscreen  v-if="vod == false" ></iframe>
       
     <table id="ptznavigataios" v-if="current_cam.ptz == 1 && vod == false" >
				      <tr>
					      <td> 
                             <v-icon class="tt"  @click="rotted('LeftUp', 0, 3, 0)">mdi-arrow-top-left</v-icon> 
                          </td> 
						  <td> 
                             <v-icon class="toparrow" @click="rotted('Up', 0, 3, 0)">mdi-arrow-up</v-icon> 
                         </td> 
						  <td> 
                             <v-icon class="tt"  @click="rotted('RightUp', 3, 3, 0)">mdi-arrow-top-right</v-icon> 
                           </td>
 					  </tr>
					  <tr>
					     <td> 
                             <v-icon  class="leftarrow"  @click="rotted('Left', 0, 3, 0)" >mdi-arrow-left</v-icon> 
                          </td> 
						  <td> 
                             <v-icon  class="homebuttons"  @click="rotted('GotoPreset', 0, 1, 0)" >mdi-home</v-icon> 
                         </td> 
						  <td> 
                             <v-icon  class="rightarrow" @click="rotted('Right', 0, 3, 0)" >mdi-arrow-right</v-icon> 
                           </td>
 					  </tr>
					  <tr>
                            <td> 
                             <v-icon class="tt"  @click="rotted('LeftDown', 3, 3, 0)" >mdi-arrow-bottom-left</v-icon> 
                          </td> 
						  <td> 
                             <v-icon class="bottomarrow"   @click="rotted('Down', 0, 3, 0)" >mdi-arrow-down</v-icon> 
                         </td> 
						  <td> 
                             <v-icon class="tt"  @click="rotted('RightDown', 3, 3, 0)" >mdi-arrow-bottom-right</v-icon> 
                           </td>
                        
                       </tr>
				  </table> 
                  <div class="zoomoutbuttons" v-if="current_cam.ptz == 1 && vod == false">
                      <v-icon large @click="zoomOut()">mdi-minus-circle</v-icon>
                  </div>
                  <div class="zoominbuttons" v-if="current_cam.ptz == 1 && vod == false">
                      <v-icon large @click="zoomIn()" >mdi-plus-circle</v-icon>
                  </div> 
                  <div class="zoominbuttons" v-if="vod">
                      <v-icon large @click="returnHome()">mdi-home</v-icon>
                  </div> 
                  <div class="voditems">
                      <v-icon large @click="vodselectDate()" >mdi-table-clock</v-icon>
                  </div>
                  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <v-card>
          <v-date-picker  :color="activecolor" @change='getSelectedDate($event)'  v-model="picker" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
      
       <v-divider></v-divider>
      <v-chip-group
         active-class="light-green-darken-3--text text--accent-4"
        :color="activecolor"
        column
        class="chipbox"
      >
        <v-dialog
      v-model="timeslotloader"
     
      persistent
      width="300"
      
    >
      <v-card
        :color="activecolor"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
        <v-chip v-for="(item, i) in timeslots"
          :key="i" @change="selectTimeSlot(item.Sign_url)">{{ item.started_at }}</v-chip>
      </v-chip-group>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'


export default {
  components: {
    Preloader
  },
  name: "Cctv",
  data() {
    return { 
       preloader: true,
       current_cam : {},
       strimurl :'',
        dialog: false,
         picker: new Date().toISOString().substr(0, 10),
         activecolor :  'light-green',
         timeslots : [],
         timeslotloader : false,
         vod : false,
         dateSelected :false,
         multiLine: false,
            snackbar: false,
            text: `Images not found on the selected date.`,
    };
  },
  mounted() {
     this.initViewer();
  },
  watch: {
  '$store.state.currentCamera': function() {
      
      
   //  this.preloader = false
     //console.log("Current data get",this.$store.state.currentCamera)
      this.current_cam = this.$store.state.currentCamera;
      this.strimurl = 'https://live.opticvyu.com:5443/LiveApp/play.html?name='+this.current_cam.channel_1;
       if(this.current_cam.cctv == "0"){
            this.$router.push('/')
       }
  }
  }, 
  methods: {
    initViewer() {
       
       

      this.current_cam = this.$store.state.currentCamera;
      if(this.current_cam.cctv == "0"){
            this.$router.push('/')
       }
      console.log("init Viewer");
      this.preloader = false,
      this.strimurl = 'https://live.opticvyu.com:5443/LiveApp/play.html?name='+this.current_cam.channel_1
    },
    rotted(dir, a1, a2, a3) {	
      this.preloader = true;
        var d = "action=start&channel=1&code="+dir+"&arg1="+a1+"&arg2="+a2+"&arg3="+a3;
	   var d2 = "action=stop&channel=1&code="+dir+"&arg1="+a1+"&arg2="+a2+"&arg3="+a3;
   
   //console.log("this.current_cam",this.current_cam);
  var camera_id = this.current_cam.camera_id;
  var cctvid = this.current_cam.cctv_id;
  var cctvpwd = this.current_cam.cctv_pwd;
  var cctvport = this.current_cam.cctv_port;
      var self = this;
axios.get('/camera/'+camera_id+'/ptzControll', { params: {ptzc : d, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport }} )
.then(function(response) {
    console.log(response,'Authenticated for start');
}).catch(function(error) {
  console.log(error,'Error on Authentication');
});
          
        setTimeout(function(){ 
            axios.get('/camera/'+camera_id+'/ptzControll', {
               params: { ptzc : d2, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport}
            }).then(function(response) {
            console.log(response,'Authenticated for stope');
               self.preloader = false;
            }).catch(function(error) {
            console.log(error, 'Error on Authentication');
            });
		}, 1000);
	 },
     zoomIn() {	
      this.preloader = true;
        var d = "action=start&channel=1&code=ZoomTele&arg1=0&arg2=0&arg3=0";
	   var d2 = "action=stop&channel=1&code=ZoomTele&arg1=0&arg2=0&arg3=0";
   
  // console.log("this.current_cam",this.current_cam);
  var camera_id = this.current_cam.camera_id;
  var cctvid = this.current_cam.cctv_id;
  var cctvpwd = this.current_cam.cctv_pwd;
  var cctvport = this.current_cam.cctv_port;
   var self = this;
axios.get('/camera/'+camera_id+'/ptzControll', { params: {ptzc : d, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport }} )
.then(function(response) {
    console.log(response,'Authenticated for start');
}).catch(function(error) {
  console.log(error,'Error on Authentication');
});
          
        setTimeout(function(){ 
            axios.get('/camera/'+camera_id+'/ptzControll', {
               params: { ptzc : d2, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport}
            }).then(function(response) {
                self.preloader = false;
            console.log(response,'Authenticated for stope');
            }).catch(function(error) {
            console.log(error, 'Error on Authentication');
            });
		}, 1000);
	 },
     zoomOut() {	
       this.preloader = true;
        var d = "action=start&channel=1&code=ZoomWide&arg1=0&arg2=0&arg3=0";
	   var d2 = "action=stop&channel=1&code=ZoomWide&arg1=0&arg2=0&arg3=0";
   
   //console.log("this.current_cam",this.current_cam);
  var camera_id = this.current_cam.camera_id;
  var cctvid = this.current_cam.cctv_id;
  var cctvpwd = this.current_cam.cctv_pwd;
  var cctvport = this.current_cam.cctv_port;
      var self = this;
axios.get('/camera/'+camera_id+'/ptzControll', { params: {ptzc : d, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport }} )
.then(function(response) {
    console.log(response,'Authenticated for start');
}).catch(function(error) {
  console.log(error,'Error on Authentication');
});
          
        setTimeout(function(){ 
            axios.get('/camera/'+camera_id+'/ptzControll', {
               params: { ptzc : d2, cctvid:cctvid, cctvpwd:cctvpwd, cctvport:cctvport}
            }).then(function(response) {
                self.preloader = false;
            console.log(response,'Authenticated for stope');
            }).catch(function(error) {
            console.log(error, 'Error on Authentication');
            });
		}, 1000);
	 },
     returnHome(){
            this.vod = false;
            this.dateSelected = false
     },
     vodselectDate(){
        this.dialog =  true;
        //this.vod = true;
     },
     getSelectedDate(data){
          this.timeslotloader = true;
          this.d1 = data;
          var d = data.split("-");
          console.log(d);
          var nd = d[0]+d[1]+d[2];

          
          
        console.log(nd, this.current_cam.camera_id);
          
            // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
            axios({ url: '/camera/'+this.current_cam.camera_id+'/chunks_resource/date/'+nd, method: 'GET' })
          .then(resp => {
               
               if(resp.data.success){
             var dd = resp.data.success;
                   
            this.timeslots = dd;
          
           // console.log(this.timeslots);
             //this.regulertimeline = false;
              //this.dialog = false;
              this.timeslotloader = false;
              this.dateSelected =  true
               }else{
                  this.snackbar = true;
                  this.timeslots =[]
                   this.timeslotloader = false;
               }
              
          })
          .catch(err => {
            console.log(err);
            this.snackbar = true;
          })
        
     },
     selectTimeSlot(imageID){
          // console.log("selectTimeSlotImage id", imageID);
              this.preloader = true;
           this.dialog =  false;
           this.vod = true;
           this.dateSelected = false
           
            var self = this;
           setTimeout(function () {
               self.$refs.vodvideos.src = imageID
               self.$refs.vodvideos.play()
                self.preloader = false;

              }, 1000);  
           
           //this.$refs.timelapsvideo.src
        
     },
  }
};
</script>
<style>
#ptzframe,.videostyle {
    width: 100%;
    height: 94vh;
    background: transparent;
    display: block;
    margin: auto;
}
/* table#ptznavigataios {
    position: absolute;
    bottom: 37px;
    left: calc(50% - 40px);
    z-index: 99;
    background: #ffffff;
    right: 9px;
    margin: auto;
    opacity: 0.4;
    transition:0.5s;
} */
table#ptznavigataios {
    position: fixed;
    top: 63px;
    /* left: calc(50% - 40px); */
    z-index: 99;
    background: #ffffff;
    right: 9px;
    margin: auto;
    opacity: 0.4;
    transition: 0.5s;
    border-radius: 50%;
    padding: 15px;
    border: 8px solid #86bc49;
    box-shadow: 0 0 20px 9px #35db007d;
}
table#ptznavigataios:hover {
    opacity: 1;
    transition:0.5s;
}
.leftarrow {
    position: relative;
    left: -14px;
   
}
.toparrow {
    position: relative;
    top: -14px;
    
}
.bottomarrow {
    position: relative;
    bottom: -14px;
    
}
.rightarrow {
    position: relative;
    right: -14px;
     
}
.homebuttons {
    color: #e7717d !important;
    border: 1px solid #eee2e2;
    border-radius: 50% !important;
    padding: 0px;
    border-radius: 1px;
    box-shadow: 0 0 20px 9px #35db007d;
}
.tt{
color: #87da2a !important ;
}
.zoomoutbuttons {
    position: fixed;
    right: 3px;
    top: 181px;
    z-index: 999;
    background: #ffffff;
    border-radius: 50%;
    padding: 3px;
    border: 5px solid #97dd44;
     opacity: 0.3;
    transition: 0.5s;
     box-shadow: 0 0 20px 9px #35db007d;
}
.zoominbuttons {
    position: fixed;
    right: 52px;
    top: 192px;
    z-index: 999;
    background: #ffffff;
    border-radius: 50%;
    padding: 9px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
     box-shadow: 0 0 20px 9px #35db007d;
}
.voditems {
    position: fixed;
    right: 108px;
    top: 166px;
    z-index: 999;
    background: #ffffff;
    border-radius: 50%;
    padding: 4px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
     box-shadow: 0 0 20px 9px #35db007d;
}
.zoomoutbuttons:hover, .zoominbuttons:hover,.voditems:hover {
    opacity: 1;
    box-shadow: 0 0 20px 3px #70db4e7d;
    border: 5px solid #8aff00;
}
   .chipbox {
    max-height: 150px;
    overflow: scroll;
} 
.chipbox.v-slide-group {
    display: block;
}
.chipbox.v-chip-group .v-slide-group__content {
    padding: 4px 24px;
}
</style>

