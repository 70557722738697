<template>
  
  <div>
    <Preloader v-if="preloader" />
       <div class="menus"> 
         
          <v-btn
      class="mx-1"
      fab
      dark
      x-small
      color="#15222b"
    >
       <v-icon
                    dark
                    color ="#80a1bd"
                  
                    @click="$refs.zoomer.zoomIn()"
                    >
                    mdi-magnify-plus 
                    </v-icon>

    </v-btn>

    <v-btn
      class="mx-1"
      fab
      dark
      x-small
      color="#15222b"
    >
       <v-icon
                    dark
                    color ="#80a1bd"
                   
                    @click="$refs.zoomer.zoomOut()"
                    >
                    mdi-magnify-minus 
                    </v-icon>

    </v-btn>

    <v-btn
      class="mx-1"
      fab
      dark
      x-small
      color="#15222b"
    >
       <v-icon
                    dark
                    color ="#80a1bd"
                   
                    @click="$refs.zoomer.reset()"
                    >
                    mdi-autorenew
                    </v-icon>

    </v-btn>
    <v-btn
      class="mx-1"
      fab
      dark
      x-small
      color="#15222b"
    >
       <v-icon
                    dark
                    color ="#80a1bd"
                   
                    @click="fullscreen()"
                    >
                    mdi-fullscreen
                    </v-icon>

    </v-btn>

     
           
      </div>
      <v-zoomer  ref="zoomer" v-bind:class="[ full ? 'fullscreen' : 'min']" style="width: 100%; height: 99vh; position: absolute; top: -47px;" pivot='image-center'>
       <img
      :src="image"
      @load="loaded"
      style="object-fit: contain; width: 100%; height: 100%;"
      
      >
      </v-zoomer>
       <v-btn
      class="mx-1 exit"
      v-bind:class="[full ? 'show' : 'hide']"
      fab
      dark
      x-small
      color="#15222b"
    >
       <v-icon
                    dark
                    color ="#80a1bd"
                    @click="fullscreenexit()"
                    >
                    mdi-fullscreen-exit
                    </v-icon>

    </v-btn>
     <div class="imageeditor" :class="{ active: showannotation }">
        <v-btn
              icon
              dark
              @click="showannotation = false"
              class="oveicon clsoe"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
                dark
                text
               @click="SaveandSend()"
                class="oveicon save"
              >
                Save
              </v-btn>
               <!-- <v-btn
                dark
                text
               @click="onlySend()"
                class="oveicon sendd"
              >
                Send
              </v-btn> -->
       <tui-image-editor ref="tuiImageEditor"   :include-ui="useDefaultUI" :options="options"></tui-image-editor>
     </div>
      <v-btn
          
            icon
            @click="preImage"
            class="left arrow"
            v-if="regulertimeline"
          >
          <v-icon>mdi-chevron-left</v-icon>
          
          </v-btn>

          <v-btn
            icon
            @click="nextImageww"
            class="right arrow"
            v-if="samedayimageIndex > 1 && regulertimeline"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn
            
            icon
            @click="preDayImage"
            class="left seven arrow"
             v-if="regulertimeline"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
          <v-btn
           
            icon
            @click="nextDayImage"
            class="right seven arrow"
             v-if="nextdayImageIndex > 0  && regulertimeline"
          >
            <v-icon>mdi-chevron-double-right</v-icon>
          
          </v-btn>
          <div class="timeline" :class="{ active: showtimeline }">
            <ul class="listitem">
               <!-- <li> 
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                     @click="boxopenshareEmails()"
                    >
                    mdi-email 
                    </v-icon>
                  </template>
                <span> Send Image By Email </span>
                </v-tooltip>
                </li> -->
               <!-- <li> 
                  <v-tooltip top>
                  <template  v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="annotadeImage"
                    >
                    mdi-square-edit-outline
                    </v-icon>
                  </template>
                <span> Image Markup </span>
                </v-tooltip>
                </li> -->
               <li>
                 <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="wathershow"
                    >
                    mdi-weather-partly-snowy-rainy 
                    </v-icon>
                  </template>
                <span>  Weather </span>
                </v-tooltip>
                </li>
               <li>
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadImage"
                    >
                    mdi-download 
                    </v-icon>
                  </template>
                <span> Download Image</span>
                </v-tooltip>
                 </li>
            
            <li>
              
              <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
      
         <v-icon  v-bind="attrs"
          v-on="on" >mdi-calendar-range</v-icon> 
      </template>
      <v-card>
          <v-date-picker :color="activecolor" @change='getSelectedDate($event)'  v-model="picker" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
      
       <v-divider></v-divider>
      <v-chip-group
       
        active-class="light-green-darken-3--text text--accent-4"
        mandatory
        :color="activecolor"
      >
        <v-dialog
      v-model="timeslotloader"
     
      persistent
      width="300"
      
    >
      <v-card
        :color="activecolor"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
        <v-chip v-for="(item, i) in timeslots"
          :key="i" @change="selectTimeSlot(item.image_id)">{{ item.updated_time }}</v-chip>
      </v-chip-group>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </li>
        <li v-if="!regulertimeline"> 
                  <v-tooltip top>
                  <template  v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="regulartimelineshow"
                    >
                    mdi-view-carousel-outline
                    </v-icon>
                  </template>
                <span> Full Timeline </span>
                </v-tooltip>
                </li>
                <li> 
                  <v-tooltip top>
                  <template  v-slot:activator="{ on, attrs }">
                    <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="reloade"
                    >
                    mdi-autorenew
                    </v-icon>
                  </template>
                <span> Refresh </span>
                </v-tooltip>
                </li>
            </ul>
            
            <v-tooltip top>
                  <template  v-slot:activator="{ on, attrs }">
                    <a class="timelinetoggle" v-bind="attrs"
                    v-on="on" :class="{ active: showtimeline }"
 @click="showtimeline = !showtimeline" >   <v-icon>mdi-arrow-up-drop-circle</v-icon> </a>
                  </template>
                <span> Full Timeline </span>
                </v-tooltip>

          <carousel :key="refresh" :autoplay="false" :items="8" :dots="false" :margin="8" :nav="true" :navText="['','']" v-if="daywiseimageidsarray.length > 0 && regulertimeline">
            <div v-for="(image, index) in daywiseimageidsarray" :key="index" @click="selectThumainal( image.prefix, image.image_name)" >
             <img :src="image.url">
             <p class="disply_date">{{ image.display_date }}</p>
           </div>
          
         </carousel>
         <carousel :key="refreshtwoo" :autoplay="false" :items="8" :dots="false" :margin="8" :nav="true" :navText="['','']" v-if="timeslots.length > 0 && !regulertimeline">
           
           <div v-for="(image, index) in timeslots" :key="index" @click="getArchiveImageId( image.image_id)" >
             <img :src="image.thumb_url">
             <p class="disply_date">{{ image.updated_time }}</p>
           </div>
         </carousel>
         </div>
          <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
     <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        color="light-green"
         v-model="sharebox"
      >
        <template >
          <v-card>
            <v-toolbar
               color="light-green"
              dark
            > Share By Email</v-toolbar>
            <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
          <v-col
          cols="12"
         sm="12"
        >
        <v-text-field
          v-model="email"
          label="Email"
          required
          :rules="emailrules"
        ></v-text-field>
       </v-col>
        <v-col
          cols="12"
          sm="12"
        >
        <v-text-field
          v-model="subject"
          label="Subject"
          required
        ></v-text-field>
         </v-col>
       
        
      <v-col
          cols="12"
          
        >
        <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        Send  <v-progress-circular
        v-if="send"
      indeterminate
      color="green"
    ></v-progress-circular>
      </v-btn>
      <v-btn @click="sharebox = false"
       color="mydarkbutton"
      >
      Cancel
      </v-btn>
       </v-col>
      </v-row>
    </v-container>
    </v-form> 
          </v-card>
        </template>
      </v-dialog> 

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="400"
        color="light-green"
         v-model="watherview"
      >
         <div class="weatherContainer text-center" style="z-index:2;">
<button data-dismiss="modal" id="popupCloseButton" class="close" title="close" style="font-size: 23px;
    opacity: .5;" @click="watherview = false">×</button>
  <div class="weatherWrapper">
    <h4 class="weatherTitle">Weather Info</h4>
    <div class="text-center"
     v-html="watherdata" id="weatherResult"></div>
  </div>
</div>
      </v-dialog> 
        
  </div>
    
</template>
<script>
import OpenSeadragon from "openseadragon";
import carousel from 'vue-owl-carousel'
import axios from 'axios'
import Preloader from '../components/PreLoader'
import {ImageEditor} from '@toast-ui/vue-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import image1 from '../assets/FKUg3r.jpg';
import Vue from 'vue'
import VueZoomer from 'vue-zoomer'
window.OpenSeadragon = OpenSeadragon;

Vue.use(VueZoomer)

export default {
  components: {
    Preloader,
    carousel,
    'tui-image-editor': ImageEditor
  },
  name: "Viewer",
  data() {
    return {
      viewer: null,
      source : {
        type: 'image',
        url : image1
      },
      send: false,
      preloader :true,
      samedayimageIndex : 0,
      nextdayImageIndex : 0,
      imageidsarray : [],
      daywiseimageidsarray : [],
      showtimeline: false,
      refresh: 0,
      refreshtwoo:100,
      thumabnailactive : 0,
       activecolor :  'light-green',
      dialogm1: '',
      dialog: false,
      picker: new Date().toISOString().substr(0, 10),
      current_cam : '',
      timeslots : [],
      timeslotloader : false,
      onedaytimeline : false,
      regulertimeline : true,
       options: {
                cssMaxWidth: 900,
                cssMaxHeight: 600,
                 includeUI: {
                    loadImage: {
                        path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                        name: 'SampleImage'
                    },
                    initMenu: 'draw',
                     menuBarPosition: 'left'
                },
                ErrorMsg : "Sorry Image is not load"
            },
            showannotation :false,
            useDefaultUI: true,
            multiLine: false,
            snackbar: false,
            text: `Images not found on the selected date.`,
            sharebox : false,
             email : '',
             message :'',
             subject : '',
              emailrules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
             
            ],
             lat : '0',
              lon : '0',
              watherview: false,
              valid : true,
              projectid : 0,
              watherdata : '',
               project : '',
               org : '',
               city : '',
               image : image1,
               full : false
    };
  },
  computed: {
      params() {
      return {
        to : this.email, 
        message : '',
        subject : this.subject,
        attachment : this.source[0].url,
        project : this.project,
        org : this.org,
        city : this.city,

      }
    } 
  },
  mounted() {
    this.initViewer();
  },
  watch: {
  '$store.state.currentCamera': function() {
     this.preloader = true
     this.samedayimageIndex = 0
     this.nextdayImageIndex = 0
     console.log("Current data get",this.$store.state.currentCamera)
       this.timeslots = [];
      this.current_cam = this.$store.state.currentCamera;
     this.getLattersImage(this.$store.state.currentCamera);
     this.nativagationImaegsList(this.$store.state.currentCamera);
     this.nativagationImaegsThumnailList(this.$store.state.currentCamera);

      let currentProject = JSON.parse(localStorage.getItem('currentProject'));
 
        // console.log('currentProject', currentProject);

         this.lat = currentProject.latitude;
         this.lon = currentProject.longitude;

         this.project = currentProject.project_name;
         this.org = currentProject.company;
         this.city = currentProject.city;
        
         this.projectid = currentProject.id;
         this.daywiseimageidsarray = [];

  },
  '$store.state.ArchiveImage': function() {
     //this.preloader = true
    //  console.log("ArchiveImage", this.$store.state.ArchiveImage)
      this.getImageUrl(this.$store.state.ArchiveImage);
  }
  }, 
  methods: {
    initViewer() {
      let currentProject = JSON.parse(localStorage.getItem('currentProject'));

         this.lat = currentProject.latitude;
         this.lon = currentProject.longitude;
          this.projectid = currentProject.id;  

           this.project = currentProject.project_name;
         this.org = currentProject.company;
         this.city = currentProject.city;
        

        // console.log('lat long', this.lat, this.lon);

       this.current_cam = this.$store.state.currentCamera
      this.getLattersImage(this.$store.state.currentCamera);
     this.nativagationImaegsList(this.$store.state.currentCamera);
     this.nativagationImaegsThumnailList(this.$store.state.currentCamera);
     // console.log("init Viewer");
       this.preloader = true;
      
    },
     nativagationImaegsThumnailList(camdata){
              //  console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata.camera_id+'/get_thumnail_images', method: 'GET' })
              .then(resp => {
              var daywiseimageidsarray = resp.data.success;
                var i = 0;
                   daywiseimageidsarray.forEach(item => {
                         
                       // console.log(item.image_name);
                        var date = item.image_name.split("_");
                        
                        var d = date[2]+"-"+date[1]+"-"+date[0];
                      //  console.log(d,date);
                        daywiseimageidsarray[i].display_date = d; 
                          i++;
                   }); 
              this.daywiseimageidsarray = daywiseimageidsarray.reverse();
             this.refresh++;
           //  console.log("Lattest Image get_thumnail_images >>", this.daywiseimageidsarray);
          })
          .catch(err => {
              this.preloader = false;
            console.log("eror", err);
           
          })
    },
    nativagationImaegsList(camdata){
             //   console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata.camera_id+'/list/images', method: 'GET' })
          .then(resp => {
             this.imageidsarray = resp.data.success;
           //  console.log("Lattest Image nativagationImaegsList >>", this.imageidsarray);
          })
          .catch(err => {
            console.log(err);
            this.preloader = false;
          })
    },
    getLattersImage(camdata){
               console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata.camera_id+'/op_dashboard_latest_images', method: 'GET' })
          .then(resp => {
            this.preloader = false;
             console.log("Lattest Image >>", resp);
             this.image = resp.data.url;

             this.source = [
              {
              type: "image",
              url: resp.data.url
              } 
            ];
           })
          .catch(err => {
            console.log("Error --> ", err);
           // console.log("Error --> ",this.preloader);
            this.preloader = false;
          })
    },
    getImageUrl(id){

        //var camera_id =  this.current_cam.camera_id;
         
           this.preloader = true
            this.image = '';
             axios({ url: '/image/'+id+'/list/url', method: 'GET', params: {
                        "cam_id":  this.current_cam.camera_id
                    } })
          .then(resp => {
             //console.log("Lattest Image Url >>", resp.data.success.image_url);
            
             this.image = resp.data.success.image_url;

         this.source = [
              {
              type: "image",
              url: resp.data.success.image_url 
              } 
            ];
          
          })
          .catch(err => {
              this.preloader = false
            console.log(err);
            //this.snackbar = true;
            //this.$router.go()

          })
    },
    preImage(){
            
        // console.log("ssss",this.samedayimageIndex);

        if(this.imageidsarray.length > 0){
           this.samedayimageIndex++ 
           
       var index_id = this.imageidsarray[this.samedayimageIndex].id;
     //  console.log("index_id", index_id);
       this.getImageUrl(index_id);

        }else{
            this.snackbar = true;
            this.text = `Image not found.`;
        }
       

    },
    nextImageww(){
          if(this.imageidsarray.length > 0){
         //    var a  =  this.samedayimageIndex;
             this.samedayimageIndex--;
            // console.log("ssss-->",this.samedayimageIndex); 

           var index_id = this.imageidsarray[this.samedayimageIndex].id;
        //  console.log("index_id", index_id);
           var v = this;
              setTimeout(function () {
                  v.getImageUrl(index_id);
              }, 100); 
          } else{
            this.snackbar = true;
            this.text = `Image not found.`;
        }
    },
    preDayImage(){

      if(this.daywiseimageidsarray.length > 0){
        this.nextdayImageIndex++ 
       //  console.log("ssss",this.nextdayImageIndex);
        //  console.log("ssss",this.daywiseimageidsarray);
         var prefix = this.daywiseimageidsarray[this.nextdayImageIndex].prefix;
        var image_name = this.daywiseimageidsarray[this.nextdayImageIndex].image_name;
     
       //  console.log("index_id", prefix);

       this.getSignUrlBykey(prefix, image_name);
        } else{
            this.snackbar = true;
            this.text = `Image not found.`;
        }
    },
    nextDayImage(){

       if(this.daywiseimageidsarray.length > 0){
         this.nextdayImageIndex-- 
       //  console.log("ssss",this.daywiseimageidsarray[this.nextdayImageIndex]);
            var prefix = this.daywiseimageidsarray[this.nextdayImageIndex].prefix;
           var image_name = this.daywiseimageidsarray[this.nextdayImageIndex].image_name; 
        //  console.log("index_id", index_id);
          this.getSignUrlBykey(prefix, image_name);
          } else{
            this.snackbar = true;
            this.text = `Image not found.`;
        }
    },
    getSignUrlBykey(prefix, name){
         this.preloader = true;
          this.image = '';
           axios({ url: '/get_sign_url_by_key?key='+prefix+'/'+name+'.jpg&exp=5minutes&project_id='+this.projectid, method: 'GET' })
          .then(resp => {
           // console.log("Lattest Sign Image >>", resp);

               this.image = resp.data.success.url;

              this.source = [
            {
             type: "image",
             url: resp.data.success.url 
            } 
            ];
              //  this.preloader = false
             //this.getImageUrl(resp);
           })
          .catch(err => {
            console.log(err);
            this.preloader = false
          })
    },
    selectThumainal(prefix, imagename){
        // console.log("thumbanail click index",prefix,imagename);
          this.getSignUrlBykey(prefix, imagename);
    },
    annotadeImage(){
            // console.log("clicked");
      //this.getBase64Image(this.source[0].url);

      this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.source[0].url, 'tempImage.jpg').then( result => {
              console.log(result);
        });
         localStorage.setItem('annotateimage', this.source[0].url);

         this.showannotation = true;
         // this.$router.push('/dashboard/annotation');
        //  let routeData = this.$router.resolve({name: 'annolation'});
          
          // setTimeout(function () {
          //          window.open(routeData.href, '_blank');
          //     }, 2000); 
            
       //  console.log("Annotation opne",this.source[0].url);
    },
 getBase64Image(url) {
  var canvas = document.createElement('CANVAS');
	var ctx = canvas.getContext('2d');
	var img = new Image;
	img.crossOrigin = 'Anonymous';
    
    let self = this;

	img.onload = function(){
		canvas.height = img.height;
		canvas.width = img.width;
	  	ctx.drawImage(img,0,0);

	  	var dataURL = canvas.toDataURL('image/png');
	  	//callback.call(this, dataURL);
       
         console.log(dataURL)
    
        self.$refs.tuiImageEditor.invoke('loadImageFromURL', dataURL, 'tempImage.jpg').then( result => {
              console.log(result);
        });
        self.$refs.tuiImageEditor.invoke('startDrawingMode', 'FREE_DRAWING', {
        width: 10,
        color: 'rgba(255, 0, 0, 0.5)',
        });
        // Clean up
	  	canvas = null; 
	};
	img.src = url;
      
    },
  downloadImage(){
        //imgEl.src
         const myImage = this.source[0].url;
       // console.log("Edit data", myImage);

         var a = document.createElement("a"); //Create <a>
    a.href =  myImage; //Image Base64 Goes here
    a.download = "Image.png"; //File name Here
    a.click(); //Downloaded file
    },

     getSelectedDate(data){
          this.timeslotloader = true;
          var d = data.split("-");
         // console.log(d);
          var nd = d[0]+d[1]+d[2];
         
       // console.log(nd, this.current_cam.camera_id);
          
            // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
            axios({ url: '/camera/'+this.current_cam.camera_id+'/list/images/date/'+nd, method: 'GET' })
          .then(resp => {
               
               if(resp.data.success){
                
             var dd = resp.data.success;
                   
            this.timeslots = dd;
          
           // console.log(this.timeslots);
             this.regulertimeline = false;
              this.dialog = false;
              this.timeslotloader = false;
              this.showtimeline = true;
              this.refreshywo++;
               }else{
                 this.snackbar = true;
                 this.timeslots = [];
                 this.regulertimeline = false;
            //  this.dialog = false;
              this.timeslotloader = false;
              //this.showtimeline = true;
               }
          })
          .catch(err => {
            console.log(err);
            this.snackbar = true;
            this.preloader = false;
          })
        
     },
     regulartimelineshow(){
            this.regulertimeline = true;
     },
     selectTimeSlot(imageID){
         //  console.log("selectTimeSlotImage id", imageID);

              this.$store.dispatch('archiveSelectTimeSlotImage', imageID)
       .then(() => {
                console.log("camera set data"); 
                this.dialog = false
       }

       )
       .catch(err => console.log(err))
     },
     

     getArchiveImageId(index_id)
     {
          this.getImageUrl(index_id);
     },
     SaveandSend(){
        
        //imgEl.src
         const myImage = this.$refs.tuiImageEditor.invoke('toDataURL');
       // console.log("Edit data", myImage);

         var a = document.createElement("a"); //Create <a>
    a.href =  myImage; //Image Base64 Goes here
    a.download = "Image.jpg"; //File name Here
    a.click(); //Downloaded file
    this.showannotation = false;
    },
    onlySend(){
         this.sharebox = true;   


         const myImage = this.$refs.tuiImageEditor.invoke('toDataURL'); 

         this.params.attachment = myImage;
    },
    boxopenshareEmails(){

       this.sharebox = true;
      // this.params.attachment = this.source[0].url;
   },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.shareEmail(this.params) // action to login
      }
    },
    shareEmail(sharedata){

         //  console.log("data", sharedata);
         this.send = true;

          axios({ url: 'https://secure.cctvtimelapse.com/api/send-with-attachment', data : sharedata, method: 'post' })
           .then(resp => {
                  console.log(resp);
                   this.sharebox = false;
                   this.send = false;

                  
         })
          .catch(err => {
            console.log(err)
             this.send = false;
               this.snackbar = true;
            this.text = `Email delivery faild.`;
            this.preloader = false;
           })
    },
    wathershow(){
        axios({ url: '/weather/project/'+this.projectid, method: 'get' })
           .then(resp => {
                //  console.log(resp);
                   this.watherdata = resp.data;
                  this.watherview = !this.watherview;  
         })
          .catch(err => {
            console.log(err)
             this.watherview = !this.watherview; 
           })                           

    },
    reloade(){
       this.$router.go();
    },
    loaded(){
               var v = this;
            this.$refs.zoomer.zoomIn(1.5);
              setTimeout(function () {
                  v.preloader = false
              }, 100);
    },
    fullscreen(){
      this.full= true;
      //console.log("hfghf", this.full);
    },
      fullscreenexit(){
      this.full= false;
      //console.log("hfghf", this.full);
    }
  }
};
</script>
<style>

.tui-image-editor-container{
    height: 100vh !important;
}
.v-application ul, .v-application ol {
     padding-left: 0px !important; 
}
.tui-colorpicker-palette-button {
    display: block;
    overflow: hidden;
    outline: none;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.tui-colorpicker-clearfix li {
    float: left;

}
.imageeditor {
    position: fixed;
    top: 50px;
    z-index: 1;
    display: none;
    width: 100%;
    height:0;
}
.imageeditor.active {
   height: auto;
    display: block;
        background: #1e1e1e;
}
.oveicon {
    position: absolute !important;
   
    z-index: 1;
}
.clsoe {
     top: 1px;
    right: 9px;
}
.save {
     top: 1px;
    right: 50px;
}
.sendd{
      top: 1px;
    right: 130px;
}
.openseadragon-container {
    position: absolute;
    top: -45px !important;
} 
.openseadragon-canvas + div {
    top: 48px !important;
    position: fixed !important;
}
.weatherContainer {
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
    min-height: 290px;
    width: auto;
    background-color: #FFF;
    padding: 10px 10px 0;
    transition: margin-right .8s ease-in-out;
    -webkit-transition: margin-right .8s ease-in-out;
    -moz-transition: margin-right .8s ease-in-out;
    -o-transition: margin-right .8s ease-in-out;
}
#weatherResult > table.table > tbody > tr:first-child td {
    font-weight: bold;
    line-height: 64px;
    font-size: 40px;
    text-transform: uppercase;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.menus {
    position: fixed;
    top: 55px;
    left: 5px;
    z-index:2;
}

.fullscreen .zoomer {
  background : #fff;
}
.vue-zoomer.fullscreen {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    z-index: 999999;
    height:100vh !important;
}
.exit{
  position: fixed !important;
  right: 10px;
  top: 10px;
  z-index: 999999;
}
.hide {
  display:none !important;
}
</style>

