<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
      Email Schedule
    </v-card-title>
          
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
        <v-col
          cols="12"
          md="6"
        >
       <v-textarea
          v-model="email"
          label="Email"
          :rules="emailrules"
          required
          rows="1"
        ></v-textarea>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        <v-text-field
          v-model="subject"
          label="Subject"
          :rules="subjectrules"
          required
        ></v-text-field>
         </v-col>
          
      <v-col
          cols="12"
          md="6"
        >
        <v-combobox
          v-model="select"
          :items="days"
          label="Select days"
          color="light-green"  
          multiple
          chips

        >
          <template v-slot:selection="data" >
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                class="accent light-green white--text"
                left
               
                v-text="data.item.slice(0, 1).toUpperCase()"
              ></v-avatar>
              {{ data.item }}
            </v-chip>
          </template>
        </v-combobox>
    </v-col> 
    <v-col  cols="12"
          md="6">
         <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
         color="light-green"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Select time (IST)*"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
             color="light-green"
            v-bind="attrs"
            
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
           color="light-green"
          full-width
          format="24hr"
          @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
    </v-col>
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
        
      >
        submit
      </v-btn>
      <v-btn @click="clear"
       color="mydarkbutton"
      >
        clear
      </v-btn>
      </v-row>
    </v-container>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'

 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "Scheduleemail",
  data() {
    return { 
      valid: true,
        subject : '',
        subjectrules: [
        v => !!v || 'This Field is required'
      ],
        email : '',
        emailrules: [
        v => !!v || 'E-mail is required',
        v => /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(v) || 'E-mail must be valid'
      ],
         preloader: false,
          alert: false,
          alertmessage: '',
          alertType: '',
         
        
       
        
         select: [],
       
        time: null,
        menu2: false,
        modal2: false,
        days : [
          'SUN','MON','TUE','WED','THU','FRI','SAT'
        ],
        mon : 0,
        tue : 0,
        wed : 0,
        thu : 0,
        fri : 0,
        sat : 0,
        sun : 0,
        currentProject : {}
        
     };
  },
  mounted() {
     this.initViewer();
     this.getPorjects();
     this.getEmailData()
  },
  watch: {
  '$store.state.currentCamera': function() {
    
     console.log("Current data get",this.$store.state.currentCamera)
    this.camera_id = this.$store.state.currentCamera.camera_id;
     this.select = [];
     this.getEmailData()
  }
  },
    computed: {
    params() {
      return {
        emails : this.email, 
        hour : this.time+":00",
        project_id : this.currentProject.id,
        subject : this.subject,
        mon : this.mon,
        tue : this.tue,
        wed : this.wed,
        thu : this.thu,
        fri : this.fri,
        sat : this.sat,
        sun : this.sun,
        camera_id : this.camera_id
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
      console.log("init Viewer");
       this.preloader = true
        this.camera_id = this.$store.state.currentCamera.camera_id; 
         this.currentProject = JSON.parse(localStorage.getItem('currentProject'));
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.sendForm() // action to login
      }
    },
   
      clear () {
        this.firstname = ''
        this.lastname = ''
        this.email = ''
        this.constactNumber = ''
        this.e7 = ''
        this.usertype = ''
      },
      getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
             //console.log("Projects", resp.data.success);
            this.projects = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

          })
          .catch(err => {
            console.log(err);
          })
      },
       
       sendForm(){

               //console.log("Selected Days",this.select);

              // console.log(this.time);

              // this.params.hour = 
            
                this.camera_id = this.$store.state.currentCamera.camera_id

                this.PreLoader = true

         
          // console.log(currentProject.id);
          // this.params.project_id = this.currentProject.id

                

                this.select.forEach(day => {
                 //  console.log("1day",day);
                   if(day == "MON"){

                     this.mon = 1

                   }else if(day == "TUE"){
                    this.tue = 1
                   }
                   else if(day == "WED"){

                      this.wed = 1   
                   }
                   else if(day == "THU"){
                    this.thu = 1
                   }
                   else if(day == "FRI"){
                     
                    this.fri = 1
        
                   }
                   else if(day == "SAT"){

                    this.sat = 1             
                   }
                   else if(day == "SUN"){
                    this.sun = 1
                   }

                });

               // console.log(this.params);

                 var self = this;
                      setTimeout(function(){ 
                           axios({ url: "/project/" +self.currentProject.id+ "/camera/" + self.camera_id + "/email-scheduler", data : self.params, method: 'post' })
          .then(resp => {
            
             //console.log("Projects", resp.data.success);
          
                      if(resp.data.success){

                    self.alert = true;
                    self.alertmessage = resp.data.success;
                    self.alertType = "success"

                 }else{

                    self.alert = true;
                    self.alertmessage = resp.data.error;
                    self.alertType = "error"
                 }
              self.PreLoader = false

          })
          .catch(err => {
            console.log(err);
             self.PreLoader = false
          })
                      }, 2000);
       },
       getEmailData(){
            
              axios({ url: "/project/" +this.currentProject.id+ "/camera/" + this.camera_id + "/email-scheduler",method: 'Get' })
          .then(resp => {
            
           //  console.log("Email Set data", resp.data.success);        
               this.email = resp.data.success.emails;
               this.subject = resp.data.success.subject;
               this.time =  resp.data.success.hour.substring(0, 5);

               
                   if(resp.data.success.mon == 1){

                     this.select.push('MON')

                   }
                    if(resp.data.success.tue  == 1){
                     this.select.push('TUE')
                   }
                    if(resp.data.success.wed  == 1){
                        this.select.push('WED') 
                   }
                    if(resp.data.success.thu  == 1){
                       this.select.push('THU')
                   }
                    if(resp.data.success.fri  == 1){
                      this.select.push('FRI')
                   }
                    if(resp.data.success.sat == 1){
  
                      this.select.push('SAT')       
                   }
                    if(resp.data.success.sun == 1){
                    
                      this.select.push('SUN')
                   }

                   this.preloader = false

          })
          .catch(err => {
            this.preloader = false
            console.log(err);
            
          })

       }
      
  }
};
</script>
<style>
</style>

