<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
      Camera ON/OFF Notication 
    </v-card-title>
          
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
        <v-col
          cols="12"
          md="6"
        >
         <v-checkbox
      v-model="checkboxactive"
      label="Notification Active"
    ></v-checkbox>
      
        <v-text-field
          v-model="email"
          label="Email"
          
          :rules="emailrules"
        ></v-text-field>
       </v-col>
       <v-col
          cols="12"
          md="12"
        >
       <v-btn
        class="mr-4"
        type="submit"
        
        color="mylightbutton"
      >
        submit
      </v-btn>

       <v-btn @click="Reset"
       color="mydarkbutton"
      >
        Reset
      </v-btn>
      </v-col>
      </v-row>
    </v-container>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'

 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "Cameranotification",
  data() {
    return { 
       valid: true,
        checkboxactive : false,
        email : '',
        emailrules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
       
         preloader: false,
          alert: false,
          alertmessage: '',
          alertType: '',
          current_cam : {}
        
     };
  },
  mounted() {
     this.initViewer();
    
  },
  watch: {
  '$store.state.currentCamera': function() {
    
     //console.log("Current data get",this.$store.state.currentCamera)

     this.current_cam = this.$store.state.currentCamera;

     this.checkboxactive  =  this.current_cam.notification_active == 1 ? true: false; 
     this.email  = this.current_cam.notification_email;
  }
  },
    computed: {
    params() {
      return {
       
         notification_email : this.email,
         notification_active : this.checkboxactive ? 1 : 0, 
       
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.addNotificaitonSettings(this.params) // action to login
      }
    },
    addNotificaitonSettings(userdata) {
        
        // console.log(userdata)

        axios({ url: '/camera/'+this.current_cam.camera_id+'/notification', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage = 'Something went wrong please reload page and try again.';
                    this.alertType = "error"
          })
      },

      Reset(){
        var userdata = {
           notification_email : 'No email Added',
           notification_active : 0, 
          }
         axios({ url: '/camera/'+this.current_cam.camera_id+'/notification', data: userdata, method: 'POST' })
          .then(resp => {
                 //   console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = "Reset succesful.";
                    this.alertType = "success";

                    this.email = '';
                    this.checkboxactive = 0;

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
                    this.alert = true;
                    this.alertmessage = 'Something went wrong please reload page and try again.';
                    this.alertType = "error"
          })
      }
     
  }
};
</script>
<style>
</style>

