<template>
  <div>
    <Preloader v-if="preloader" />
    <tui-image-editor ref="tuiImageEditor"  :include-ui="useDefaultUI" :options="options"></tui-image-editor>
   <v-btn
  elevation="2"
   class="downloadButtons"
   @click="SaveandSend()"
> Download 
</v-btn> 
    </div>
    
</template>
<script>
import Preloader from '../../components/PreLoader'

import {ImageEditor} from '@toast-ui/vue-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
export default {
  components: {
    Preloader,
     'tui-image-editor': ImageEditor 
  },
  name: "Annotation",
  data() {
    return {
      preloader :true,
      useDefaultUI: true,
      options: {
                cssMaxWidth: 900,
                cssMaxHeight: 600,
                 includeUI: {
                    loadImage: {
                        path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                        name: 'SampleImage'
                    },
                    initMenu: 'draw',
                     menuBarPosition: 'left'
                },
                ErrorMsg : "Sorry Image is not load"
            }
    };
  },
  mounted() {
    //this.annotadeImage();
    this.initViewer();
    //console.log("annotateimage",localStorage.getItem('annotateimage'));

      
  
  },
  watch: {
  '$store.state.currentCamera': function() {
   //  this.preloader = true
     //console.log("Current data get",this.$store.state.currentCamera)
   
  }
  }, 
  methods: {
    initViewer() {
      var u = localStorage.getItem('annotateimage');
     
     // console.log("uuuuuuuuuuu", u);

      this.getBase64Image(u);
     // console.log("init Viewer");
       this.preloader = false;
       //localStorage.getItem('annotateimage')
        
        
   },
    SaveandSend(){
        //imgEl.src
         const myImage = this.$refs.tuiImageEditor.invoke('toDataURL');
      //  console.log("Edit data", myImage);

         var a = document.createElement("a"); //Create <a>
    a.href =  myImage; //Image Base64 Goes here
    a.download = "Image.jpg"; //File name Here
    a.click(); //Downloaded file
    },

    getBase64Image(url) {
  var canvas = document.createElement('CANVAS');
	var ctx = canvas.getContext('2d');
	var img = new Image;
	img.crossOrigin = 'Anonymous';
    
    let self = this;

	img.onload = function(){
		canvas.height = img.height;
		canvas.width = img.width;
	  	ctx.drawImage(img,0,0);

	  	var dataURL = canvas.toDataURL('image/png');
	  	//callback.call(this, dataURL);
       
       //  console.log(dataURL)
    
        self.$refs.tuiImageEditor.invoke('loadImageFromURL', dataURL, 'tempImage.jpg').then( result => {
              console.log(result);
        });
        self.$refs.tuiImageEditor.invoke('startDrawingMode', 'FREE_DRAWING', {
        width: 10,
        color: 'rgba(255, 0, 0, 0.5)',
        });
        // Clean up
	  	canvas = null; 
	};
	img.src = url;
      
    },
  }

};
</script>
<style>

.tui-image-editor-container{
    height: 92.7vh !important;
}
.v-application ul, .v-application ol {
     padding-left: 0px !important; 
}
.tui-colorpicker-palette-button {
    display: block;
    overflow: hidden;
    outline: none;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.tui-colorpicker-clearfix li {
    float: left;

}
.tui-image-editor-container .tui-image-editor-header{
    display:none;
}
.tui-image-editor-container .tui-image-editor-main{
 top:0 !important;
}
.downloadButtons {
    position: absolute;
    right: 11px;
    top: 11px;
}
</style>

