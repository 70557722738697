<template>
 
  <v-main class="1pt-0">
      <Navbar />
     <router-view></router-view>
  </v-main>
</template>

<script>
import Navbar from "../../components/Navbar";

export default {
  name: "Mainlayout",
  components: { Navbar },
  data: () => ({
    //
  }),
};
</script>
<style>

</style>