<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="9" class="left backgroundimage">
       
      </v-col>
      <v-col cols="3" class="right">
       
        <h2>Reset Password </h2>
        <validation-observer ref="observer">
          <v-form @submit.prevent="submit">
            <validation-provider v-slot="{ errors }" name="Name" rules="required|email">
              <v-text-field
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="email" rules="required">
              <v-text-field
                v-model="password"
                :error-messages="errors"
                label="Password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
            </validation-provider>
            <div class="text-center">

             <validation-provider v-slot="{ errors }" name="email" rules="required">
              <v-text-field
                v-model="password_confirmation"
                :error-messages="errors"
                label="Confirm Password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
            </validation-provider>

              <v-btn class="signin-btn singbuttons" type="submit" rounded>
                Submit
              </v-btn>
            </div>
             
          </v-form>
          
                 
            
        </validation-observer>
        <div class="text-center">
               <v-alert
      dense
      outlined
      :type="type"
      class="mt-5"
      v-if="error"
    >
     {{errorMessage}}
    </v-alert>

    
     </div>
     
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
import axios from 'axios'
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    email: '',
    password: null,
    showPass: false,
    error : false,
    errorMessage : '',
    type : '',
    loginerror: true
  }),
  computed: {
    params() {
      return {
        token : this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        
      }
    }
  },
   mounted() {
var url_string = window.location.href
var url = new URL(url_string);
  this.token = url.searchParams.get("token");
   this.email= url.searchParams.get("email");

    console.log(this.params);
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
         //console.log("Hello i am here.");
        this.login(this.params) // action to login
      }
    },
  
   
    login(data) {
         axios({ url: '/password/reset', data : data, method: 'POST' })
.then(response => {
    console.log(response, "response Link send Sccessful");
     var v = this;
      if(response.data.success){
         this.error = true;
         this.type = 'success';
           this.errorMessage = 'Password Reset Successful.';
           
            
              setTimeout(function () {
                  v.$router.push('/login')
              }, 3000);
      }else{
        this.error = true;
         this.type = 'error';
         this.errorMessage = response.data.error;
        
         setTimeout(function () {
                  v.$router.push('/login')
              }, 3000);
      }
       
      }) 
       .catch(err => {
         console.log(err)
         //  var v = this;
         this.error = true;
         this.errorMessage = 'Something went wrong please check your Email or Resend email.';
         this.type = 'error';
          
        //  setTimeout(function () {
        //           v.$router.push('/login')
        //       }, 3000);
         })
      },
    
    }
  } 
</script>
<style>
  .loginerror{
     
  }
</style>