import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/styles.scss";
import "./assets/styles.scss";
import Axios from 'axios'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
    Vue.prototype.$http.defaults.baseURL = 'https://api.cctvtimelapse.com/api'
    if (token) {
      Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
    }
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
