import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "@/views/Home.vue";
import Archive from "@/views/DashboardPages/Archive";
import Compare from "@/views/DashboardPages/Compare";
import Timelapse from "@/views/DashboardPages/Timelaps";
import Splitscreen from "@/views/DashboardPages/Splitscreen";
import Annotation from "@/views/DashboardPages/annotation";
import Cctv from "@/views/DashboardPages/Cctv";

import Adduser from "@/views/DashboardPages/SettingsPages/AddUser";
import Camerainfo from "@/views/DashboardPages/SettingsPages/Camerainfo";
import CameraNotifications from "@/views/DashboardPages/SettingsPages/CameraNotifications";
import ChangePassword from "@/views/DashboardPages/SettingsPages/ChangePassword";
import CustomizeTimelapse from "@/views/DashboardPages/SettingsPages/CustomizeTimelaps";
import ManagaUser from "@/views/DashboardPages/SettingsPages/ManageUser";
import ScheduleEmail from "@/views/DashboardPages/SettingsPages/ScheduleEmail";
import Login from "@/views/Login.vue";
import ResetPassword from "@/views/ResetPassword";
import Mainlayout from "@/views/Layout/MainLayout.vue";


// import VueItUp from "../views/VueIyUp.vue"

Vue.use(VueRouter);

const routes = [
 
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/reset-password",
    name: "Reset-password",
    component: ResetPassword
  },
  {
    path: '/',
    component: Mainlayout,
    redirect: '/portal',
    name: 'home',
    hidden: false,
    meta: {  
      requiresAuth: true,
      access: true   
      
    },
    children: [
      {
        name : 'dashboard', 
        path: 'portal',
        meta: {
          title: 'Dashboard',
          icon: 'dashboard',
          access: true   
        },
        component: Home
      },
      {
        name : 'annolation', 
        path: '/portal/annotation',
        component: Annotation,
        meta: {
          access: true   
        }
      },
      {
        name : 'archive',
        path: '/portal/archive',
        component: Archive,
        meta: {
          access: true   
        }
      },
      {
        name : 'compare',
        path: '/portal/compare',
        component: Compare,
        meta: {
          access: true   
        }
      },
      {
        name : 'splitescreen',
        path: '/portal/splitscreen',
        component: Splitscreen,
        meta: {
          access: true   
        }
      },
      {
        name : 'timelapse',
        path: '/portal/timelapse',
        component: Timelapse,
        meta: {
          access: true   
        }
      },
      {
        name : 'cctv',
        path: '/portal/live',
        component: Cctv,
        meta: {
          access: true   
        }
      },
      {
        name : 'adduser',
        path: '/portal/settings/adduser',
        component: Adduser,
        meta: {
          access: false   
        }
      },
      {
        name : 'camerainfo',
        path: '/portal/settings/camerainfo',
        component :Camerainfo,
        meta: {
          access: false   
        }
      },
      {
        name : 'cameranotification',
        path: '/portal/settings/camera-notifications',
        component: CameraNotifications,
        meta: {
          access: false    
        }
      },
      {
        name : 'changepassword',
        path: '/portal/settings/change-password',
        component: ChangePassword,
        meta: {
          access: true    
        }
      },
      {
        name : 'customizetimelapse',
        path: '/portal/settings/customize-timelapse',
        component: CustomizeTimelapse,
        meta: {
          access: false   
        }
      },
      {
        name : 'manageuser',
        path: '/portal/settings/managa-user',
        component: ManagaUser,
        meta: {
          access: true    
        }
      },
      {
        name : 'schedulemail',
        path: '/portal/settings/schedule-email',
        component: ScheduleEmail,
        meta: {
          access: false 
        },
      }

    ]
  }
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

 var userdata = {};
 // console.log('to',to);
  if(to.name == 'Login' || to.name == 'dashboard' || to.name == 'Reset-password'  ){ 
   userdata = {
      user_code : 1
    }
  }else{
   userdata = JSON.parse(localStorage.getItem('userdata'));
    //console.log(userdata,"userdata");
  }
  if(userdata.user_code == 2 || userdata.user_code == 0){
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/login')
    } else {
      next()
    }
  }else{
    if(to.matched.some(record => record.meta.requiresAuth) && to.matched.some(record => record.meta.access)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/login')
    } else {
      next()
    } 
  }

  
})

export default router;
