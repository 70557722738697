<template>
  
  <div>
    <Preloader v-if="preloader" />
        
       <div
    v-ripple="{ center: true }"
    class="text-center elevation-1 pa-0 headline"
  >
     <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
      
         
           <v-btn
      class="ma-2"
      outlined
      color="light-green"
        v-bind="attrs"
         v-on="on" 
    >
      {{ d1 }}
     <v-icon  right   v-bind="attrs"
         >mdi-calendar-range</v-icon> 
    </v-btn>

      </template>
      <v-card>
          <v-date-picker :color="activecolor" @change='getSelectedDate($event)'  v-model="picker" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
      
       <v-divider></v-divider>
      <v-chip-group
       
        active-class="light-green-darken-3--text text--accent-4"
        multiple
        :color="activecolor"
      >
        <v-dialog
      v-model="timeslotloader"
     
      persistent
      width="300"
      
    >
      <v-card
        :color="activecolor"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
        <v-chip v-for="(item, i) in timeslots"
          :key="i"  @change="selectTimeSlot(item.image_id)">{{ item.updated_time }}</v-chip>
      </v-chip-group>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
     <v-btn
              
              color="success"
              dark
              @click="swithmode"
            >
             Switch Mode
            </v-btn>

    <v-dialog
      v-model="dialogtwo"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
      
         
           <v-btn
      class="ma-2"
      outlined
      color="light-green"
        v-bind="attrs"
         v-on="on" 
    >
      {{ d2 }}
     <v-icon  right   v-bind="attrs"
         >mdi-calendar-range</v-icon> 
    </v-btn>

      </template>
      <v-card>
          <v-date-picker :color="activecolor" @change='getSelectedDatetwo($event)'  v-model="pickertwo" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
      
       <v-divider></v-divider>
      <v-chip-group
       
        active-class="light-green-darken-3--text text--accent-4"
        multiple
        :color="activecolor"
      >
        <v-dialog
      v-model="timeslotloader"
     
      persistent
      width="300"
      
    >
      <v-card
        :color="activecolor"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
        <v-chip v-for="(item, i) in timeslotstwo"
          :key="i" @change="selectTimeSlottwo(item.image_id)">{{ item.updated_time }}</v-chip>
      </v-chip-group>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogtwo = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogtwo = false"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <div class="comarebox" v-if="comapreview">
         <VueCompareImage :leftImage="compareimage1" :rightImage="compareimage2" />
    </div>    
    <div class="splitview" v-if="!comapreview">
    <v-zoomer class="zzoomer" >
  <img
    :src="compareimage1"
    style="object-fit: contain; width: 100%; height: 100%;"
  >
</v-zoomer>
    <v-zoomer class="zzoomer">
  <img
    :src="compareimage2"
    style="object-fit: contain; width: 100%; height: 100%;"
  >
</v-zoomer>
    </div>
           <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      right
      timeout="3000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
   </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import image1 from '../../assets/webcam-security.jpg'
import image2 from '../../assets/webcam-security-001.jpg'
import VueCompareImage from 'vue-compare-image';
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)
export default {
  components: {
    Preloader,
    VueCompareImage 
  },
  name: "Compare",
  data() {
    return { 
        preloader: false,
         dialog: false,
         dialogtwo: false,
         picker: new Date().toISOString().substr(0, 10),
         pickertwo: new Date().toISOString().substr(0, 10),
         activecolor :  'light-green',
         timeslots : [],
         timeslotstwo : [],
         compareimage1 : image1,
         compareimage2 : image2,
         timeslotloader : false,
         comapreview:true,
         d1:'Select Date',
         d2 :'Select Date',
         multiLine: false,
         snackbar: false,
         text: `Images not found on the selected date.`,

    };
  },
  mounted() {
     this.initViewer();
  },
  watch: {
  '$store.state.currentCamera': function() {
     //this.preloader = true
    // console.log("Current data get",this.$store.state.currentCamera)
     this.timeslots = [];
     this.timeslotstwo = [];
      this.current_cam = this.$store.state.currentCamera;
      this.compareimage1 = image1;
      this.compareimage2 = image2;
    
  }
  }, 
  methods: {
    initViewer() {
        this.current_cam = this.$store.state.currentCamera
      //console.log("init Viewer");
       this.preloader = false 
    },
    getSelectedDate(data){
          this.timeslotloader = true;
          this.d1 = data;
          var d = data.split("-");
          //console.log(d);
          var nd = d[0]+d[1]+d[2];

          
          
      //  console.log(nd, this.current_cam.camera_id);
          
            // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
            axios({ url: '/camera/'+this.current_cam.camera_id+'/list/images/date/'+nd, method: 'GET' })
          .then(resp => {
           
             if(resp.data.error){
               this.snackbar = true;
             }
    
             var dd = resp.data.success;
                   
            this.timeslots = dd;
          
           // console.log(this.timeslots);
             //this.regulertimeline = false;
              //this.dialog = false;
              this.timeslotloader = false;
              
          })
          .catch(err => {
            console.log(err);
             this.snackbar = true;
          })
        
     },
     selectTimeSlot(imageID){
         //  console.log("selectTimeSlotImage id", imageID);
           this.getImageUrlfirst(imageID);
           this.preloader = true;
     },
    getImageUrlfirst(id){
         
            this.preloader = true
             axios({ url: '/image/'+id+'/list/url', method: 'GET', params: {
                        "cam_id":  this.current_cam.camera_id
                    } 
                  })
          .then(resp => {
             this.compareimage1 = resp.data.success.image_url; 
             this.dialog = false;
              var v = this;
              setTimeout(function () {
                  v.preloader = false
              }, 3000);
            //this.viewer.tileSources = this.source;
          })
          .catch(err => {
            console.log(err);
               this.snackbar = true;
          })
    },
    getSelectedDatetwo(data){
            this.d2 = data;
          this.timeslotloader = true;
          var d = data.split("-");
         // console.log(d);
          var nd = d[0]+d[1]+d[2];
          
        //console.log(nd, this.current_cam.camera_id);
          
            // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
            axios({ url: '/camera/'+this.current_cam.camera_id+'/list/images/date/'+nd, method: 'GET' })
          .then(resp => {
               
                if(resp.data.error){
               this.snackbar = true;
             }
             var dd = resp.data.success;
                   
            this.timeslotstwo = dd;
          
            //console.log(this.timeslots);
             //this.regulertimeline = false;
              //this.dialog = false;
              this.timeslotloader = false;
              
          })
          .catch(err => {
            console.log(err);
          })
        
     },
     selectTimeSlottwo(imageID){
         //  console.log("selectTimeSlotImage id", imageID);
           this.getImageUrlSecond(imageID);
           this.preloader = true;
     },
    getImageUrlSecond(id){
         
            this.preloader = true
             axios({ url: '/image/'+id+'/list/url', method: 'GET', params: {
                        "cam_id":  this.current_cam.camera_id
                    } })
          .then(resp => {
             this.compareimage2 = resp.data.success.image_url; 
             this.dialogtwo = false;
             
             
               var v = this;
              setTimeout(function () {
                  v.preloader = false
              }, 3000);
            
              //this.viewer.tileSources = this.source;
          })
          .catch(err => {
            console.log(err);
          })
    },
       swithmode(){
          this.preloader = true
            this.comapreview = !this.comapreview;
          //  console.log("Swith Mode",this.comapreview)
            var v = this;
              setTimeout(function () {
                  v.preloader = false
              }, 2000);
       }
  }
};
</script>
<style>
 .comarebox{
    display: block;
    width:80%;
    margin:auto;
 }
 .zzoomer{
   width:50%;
   display:inline-block;

 }
</style>

