<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="pa-5"
          
        >
         <v-card-title>
      Add New User
    </v-card-title>
          
     <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submit"
    >

         <v-container>
          <v-row> 
        <v-col
          cols="12"
          md="6"
        >
        <v-text-field
          v-model="firstname"
          label="First Name"
          required
          :rules="firstnamerules"
        ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
        <v-text-field
          v-model="lastname"
          label="Last Name"
          :rules="firstnamerules"
          required
        ></v-text-field>
         </v-col>
          <v-col
          cols="12"
          md="6"
        >
        <v-text-field
          v-model="email"
          label="Email"
          required
          :rules="emailrules"
        ></v-text-field>
       </v-col>
       <v-col
          cols="12"
          md="6"
        >
        <v-text-field
          v-model="constactNumber"
          label="Contact Number"
        ></v-text-field>
       </v-col>
        
     <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="e7"
          :items="projects"
          label="Projects"
          item-text="project_name"
          item-value="id"
          multiple
          chips
          hint="Select Projects for user"
          persistent-hint
        >
        </v-select>
      </v-col>
      <v-col
          cols="12"
          md="6"
        >
       <v-radio-group
      v-model="usertype"
      row
    >
      <v-radio
        label="Make Normal User"
        value="1"
      ></v-radio>
       <v-tooltip bottom >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-progress-question
        </v-icon>
      </template>
      <span>Privileges:- Add User, Change Password,<br> See Existing Users</span>
      </v-tooltip>
       <span class="pa-2" ></span>
      <v-radio
        label="Make Admin"
        value="0"
      ></v-radio>
     
       <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-progress-question
        </v-icon>
      </template>
       <span>Privileges :- Add User, Change Password,<br> See Existing Users, Email Scheduler,<br> Camera Tag,  Camera Info,<br> Camera Notification, Customize Time-lapse Video </span>
    </v-tooltip>
    </v-radio-group>
    </v-col> 
      <v-btn
        class="mr-4"
        type="submit"
        :disabled="!valid"
        color="mylightbutton"
      >
        submit
      </v-btn>
      <v-btn @click="clear"
       color="mydarkbutton"
      >
        clear
      </v-btn>
      </v-row>
    </v-container>
    </v-form>
             
        </v-card>
      </v-col>
      <v-col
        cols="3"
        md="3"
        out-line
      >
        <Sidebar/>
      </v-col>
    </v-row>
      
       
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'

 
export default {
  components: {
    Preloader,
      Sidebar,
  },
  name: "Adduser",
  data() {
    return { 
      valid: true,
       firstname: '',
       lastname : '',
        firstnamerules: [
        v => !!v || 'This Field is required'
      ],
        email : '',
        emailrules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
        constactNumber : '',
         preloader: false,
          alert: false,
          alertmessage: '',
          alertType: '',
          e7: [],
        
        usertype : '1',
        projects : {},
        useraccess : false
        
     };
  },
  mounted() {
     this.initViewer();
     this.getPorjects();

       this.userdata = JSON.parse(localStorage.getItem('userdata'))
         if(this.userdata.user_code == 2){
              this.useraccess = true
        }else{
          this.$router.push('/')
        }
  },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
    
  }
  },
    computed: {
    params() {
      return {
        firstname : this.firstname, 
        lastname : this.lastname,
        email : this.email,
        contact : this.constactNumber,
        project : this.e7[0],
        project_ids : this.e7.toString(),
        user_code : this.usertype,
        cctvtimelapse_user : 1
      }
    }
  }, 
  methods: {
     validate () {
        this.$refs.form.validate()
      },
    initViewer() {
     // console.log("init Viewer");
       this.preloader = false 
    },
      async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {       
        this.adduser(this.params) // action to login
      }
    },
    adduser(userdata) {
        
       //  console.log(userdata)

        axios({ url: '/users', data: userdata, method: 'POST' })
          .then(resp => {
                   // console.log("Resp", resp);
                 if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = 'User added successfully. Login instructions have been emailed to the new user.';
                    this.alertType = "success"
                     this.clear();

                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                 }
       })
          .catch(err => {
            console.log(err);
          })
      },
      clear() {
        this.firstname = ''
        this.lastname = ''
        this.email = ''
        this.constactNumber = ''
        this.e7 = ''
        this.usertype = ''
      },
      getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.projects = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

          })
          .catch(err => {
            console.log(err);
          })
      },
  }
};
</script>
<style>
</style>

