<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          class="vhfix"
         >
         <v-card-title>
      Camera Info
    </v-card-title>
           <v-list 
         >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      <v-list-item  v-for="(item, i) in items"
           :key="i"
          active-class="actiaveitem" color="light-green"   @click="getCamerasbyProject(item)" > 
       <template >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-list-item-icon>
        </template>
      </v-list-item>  
    </v-list-item-group>
    </v-list>       
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="minheights"
         >
             <v-card
      dark
      flat
    >

      <v-card-title class="pa-2 light-green">
        
        <h3 class="title font-weight-light text-center grow">
         {{ CurrentPname }} - Camera(s) Timeline info
        </h3>
        
      </v-card-title>
      
    </v-card>
            <v-timeline
        align-top
        dense
      >
        <v-timeline-item
             
          v-for="(item, i) in camdata"
           :key="i"   
           
          :color="item.status?'light-green':'pink'"
          small

        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>CAM {{item.camname}} </strong>
            </v-col>
            <v-col>
              <strong>{{item.lastdate}} </strong>
              <div class="caption">
                CAM {{item.camname}} is {{ item.status? 'Active' : 'Deactivate' }}, Last Image Taken on <strong>{{item.lastdate}} </strong>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>


        </v-card>
      </v-col>
        <v-col
        cols="3"
        md="3"
        out-line
      >
        <Sidebar />
      </v-col>
    </v-row>
      
        
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
import Sidebar from '../../../components/SettingSidebar'

 
export default {
  components: {
    Preloader,
    Sidebar

  },
  name: "cameraInfo",
  data() {
    return { 
      preloader : false,
       items: {} ,
       camers : [],
       camdata : [],
       CurrentPname : ''
       
    }
  },
  mounted() {
     this.getPorjects();

    
  },
  watch: {
  '$store.state.currentCamera': function() {
    
    // console.log("Current data get",this.$store.state.currentCamera)
       this.camers = [];
  }

  },

  methods: {
     getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
            // console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

               this.getCamerasbyProject(this.items[0]) 
          })
          .catch(err => {
            console.log(err);
          })
      },
   
        getCamerasbyProject(projectId){   

           this.preloader = true;
           
          // console.log(projectId);
            this.CurrentPname = projectId.project_name;
            this.camers = {}
            this.camdata = []
          axios({ url: '/camera/'+projectId.id, method: 'GET' })
          .then(resp => {
              this.camers = resp.data.success.filter(function(item){
                 return item.camera_active == 1;         
               });

             // console.log("Cameras", this.camers);
               var a = 1;
               this.camers.forEach(cam => {
                       this.getLattersImage(a, cam)

                       a++;
               });
               this.preloader = false;
           })
          .catch(err => {
            console.log(err);
          })
       },

       getLattersImage(camnumber, camdata){
               // console.log("lates images cam data",camdata);
        axios({ url: '/camera/'+camdata.camera_id+'/latest_images', method: 'GET' })
          .then(resp => {
           //  console.log("Lattest Image >>", resp.data.success[0].image_name);
      
                var d = resp.data.success[0].image_name.split("_");

                 var datetime = d[0]+"-"+d[1]+"-"+d[2]+" "+d[3]+":"+d[4]+":"+d[5]

               
              
                let dateOneObj = new Date(datetime);
                let dateTwoObj = new Date();
               // console.log(dateTwoObj);
                let milliseconds = Math.abs(dateTwoObj - dateOneObj);
                let hours = milliseconds / 36e5;
               // console.log(hours);
                let status = true;
                 if( hours > 2 ){
                   status = false;
                 }
                 
             this.camdata.push({ camname : camnumber, lastdate : datetime, status: status });
           })
          .catch(err => {
            console.log(err);
          })
    },
    getImageUrl(id){
         
            this.preloader = true
             axios({ url: '/image/'+id+'/list/url', method: 'GET' })
          .then(resp => {

             console.log("Lattest Image Url >>", resp.data.success);
          
        
          })
          .catch(err => {
            console.log(err);
          })
    },
    
  }
};
</script>
<style>
.vhfix{
 
  overflow:auto;
}
.minheights {
    min-height: 91vh;
}
</style>

